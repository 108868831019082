export default {
    path: "/",
    component: _=>import('@/views/Website'),
    meta: {
        showChat: true
    },
    children: [
        { path: 'auth', component: _ => import('../views/Admin/Auth'), children: [
                {name: 'auth_landing', path: 'auth_landing', component: _=>import('../components/Website/Customer/AuthLanding')}
            ]
        },
        {
            name: 'home', path: '', component: _=> import('@/views/Website/Home')
        },
        {
            name: 'categories',
            path: 'categories/:slug',
            component: _=> import('@/views/Website/Categories/Categories')
        },
        {
            name: 'store_front',
            path: 'store_front/:id',
            component: _=> import('@/views/Website/StoreFront/StoreFront')
        },
        {
            name: 'terms',
            path: 'terms',
            component: _=> import('@/views/Website/AboutUs/Terms')
        },
        {
            name: 'privacy',
            path: 'privacy',
            component: _=> import('@/views/Website/AboutUs/PrivacyPolicy')
        },
        {
            name: 'cart',
            path: 'cart',
            component: _=> import('@/views/Website/Purchase/Cart')
        },
        {
            name: 'business',
            path: 'business',
            component: _=> import('@/views/Website/Business/Business')
        },
        {
            name: 'notifications',
            path: 'notifications',
            component: _=> import('@/views/Website/Notifications')
        },
        {
            path: 'sell-with-us',
            name: 'sell-with-us',
            component: _=> import('@/views/Website/Business/SellWithUs')
        },
        {
            name: 'search_landing',
            path: 'search_landing/:id?',
            component: _=> import('@/views/Website/Categories/SearchLanding')
        },
        {
            name: 'products-search',
            path: 'products-search/:search',
            component: _=> import('@/views/Website/Search')
        },
        {
            name: 'terms_&_conditions',
            path: '/terms_&_conditions',
            component: _=> import('@/views/Website/AboutUs/Terms')
        },
        {
            name: 'privacy_policy',
            path: '/privacy_policy',
            component: _=> import('@/views/Website/AboutUs/PrivacyPolicy')
        },
        {
            name: 'about_akokomarket',
            path: '/about_akokomarket',
            component: _=> import('@/views/Website/AboutUs/AboutUs')
        },
        {
            name: 'returns_and_refunds',
            path: '/returns_and_refunds',
            component: _=> import('@/views/Website/AboutUs/Returns')
        },
        {
            name: 'contact_us',
            path: '/contact_us',
            component: _=> import('@/views/Website/Support/ContactUs')
        }
    ]
}
