<template>
    <v-flex xs12 sm12 >
        <v-card rounded="xl">
            <v-card-text>
                <v-container>
                    <transition
                        group
                        name="bounce"
                        enter-active-class="bounceInDown"
                        leave-active-class="bouceOutUp"
                    >
                        <sign-in key="sign_in" v-if="s_in"> </sign-in>
                        <sign-up key="sign_up" v-else> </sign-up>
                    </transition>

                </v-container>
            </v-card-text>
        </v-card>
    </v-flex>
</template>

<script>

    import SignIn from   './Signin'
    import SignUp from   './Signup'
    export default{
        data(){
            return {
                bookreq:false,
                hidden:false,
                gold_package:[],
            }
        },
        computed:{
            user(){
                return this.$store.getters.user
            },
            s_in(){
                return this.$store.getters.showLogin;
            },
        },
        watch:{

        },
        mounted(){
            // this.gold_package_set();
        },
        methods:{
            goHome(){
                this.$router.push('/')
            },
            onSignin(){
                console.log({email: this.email,password: this.password, conf: this.confirmPassword})
                this.$store.dispatch('signUserIn',{email:this.email,password:this.password})
            }
        },
        components:{
            SignIn:SignIn,
            SignUp:SignUp
        }

    }
</script>
