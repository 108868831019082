// https://vuex.vuejs.org/en/mutations.html

export default {
  setUser(state, payload) {
    state.user_data = payload;
  },
  setHead(state, payload) {
    state.show_head = payload;
  },
  setSnack(state, payload) {
    state.snackSet = payload;
  },
  setCompanyDialog(state, payload) {
    state.companyDialog = payload;
  },
  setCreateProductDialog(state, payload) {
    state.createItemDialog = payload;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
  setLogin(state, payload) {
    state.showLogin = payload;
  },
  setLoginState(state, payload) {
    state.loginState = payload;
  },
  setPhone(state, payload) {
    state.phone = payload;
  },
  setCartMenu(state, payload) {
    state.cart_menu = true;
  },
  clearCart(state,payload){
    state.activeCart = [];
  },
  setCart(state,payload){
    let existCheck = state.activeCart.find(e=>{
        return e.id === payload.id;
    });
    if(!existCheck){
      state.activeCart.push(payload);
    }
    else{
        state.activeCart = []
        state.activeCart.push(payload);
    }
  }
};
