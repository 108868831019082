<template>
    <div>
        <v-dialog
                style="border-radius:20px"
                v-model="phone_verification"
                width="300"
                persistent
        >
            <v-card style="border-radius:20px" flat>
                <v-toolbar dense flat>
                    <v-btn icon @click.native="closeVerify">
                        <v-icon color="grey darken-1">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-title class="subheading grey--text text--darken-2">
                    <v-icon color="red" left>mdi-shield-check</v-icon>
                    Verify Phone Number
                </v-card-title>
                <div class="px-6 text-center caption mx-2 my-4">
                    We have sent an SMS with a code to
                    <i class="font-weight-bold">{{ phoneObj.e164 }}</i
                    >, please Enter the 6-digit activation code to complete signin.
                </div>

                <v-card-text class="mb-n6">
                    <v-form>
                        <v-text-field
                                dense
                                rounded
                                outlined
                                v-model="vcode"
                                prepend-inner-icon="mdi-message-processing"
                                placeholder="6-digit code"
                                required
                        />
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn small color="grey" text @click="login">
                        <v-icon>mdi-refresh</v-icon>
                        Re-Send Code
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn :loading="loading" :disabled="loading" rounded color="primary" @click="checkCode">
                        Verify
                        <v-icon right>mdi-arrow-right</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-layout
         row v-if="error">
            <v-flex xs12 sm12>
                <app-alert
                        @dismissed="onDismissed"
                        value="true"
                        text="errormessage"
                ></app-alert>
            </v-flex>
        </v-layout>
        <v-flex xs12 sm11 offset-sm1> </v-flex>
        <form class="fup elevation-3" @submit.prevent="login" method="post">
            <v-tabs
                fixed-tabs
                background-color="primary"
                dark
            >
                <v-tab>Customer</v-tab>
                <v-tab>Sell with us</v-tab>
            </v-tabs>

            <v-layout row>
        <v-flex xs10 offset-xs1>
            <akoko-phone
                class="my-3"
                @update="userPhoneNo"
                v-model="phone"
                size="sm"
                default-country-code="GH"
            >
            </akoko-phone>
        </v-flex>
    </v-layout>
    <v-layout row class="my-2">
        <v-flex xs10 offset-xs1>
            <v-btn
                id="sign_btn"
                block
                class="primary mb-2"
                rounded
                :loading="loading"
                @click.native="loader = 'loading'"
                type="submit"
            >
                <v-icon left>mdi-check</v-icon>
                Sign Up
                <span slot="loader" class="custom-loader">
                    <v-icon light>cached</v-icon>
                </span>
            </v-btn>
        </v-flex>
    </v-layout>
    <v-divider></v-divider>
    <v-row
        justify="space-around"
    >
        <div class="col-10 col-sm-5"
        >
            <v-btn small :loading="google_loading" block color="red" class="white--text" @click="googleLogin">
                <v-icon left>mdi-google</v-icon>
                Gmail
            </v-btn>
        </div>
        <div class="col-10 col-sm-5"
        >
            <v-btn small :loading="facebook_loading" block color="blue" class="white--text" @click="facebook_login">
                <v-icon left>mdi-facebook</v-icon>
                Facebook
            </v-btn>
        </div>
    </v-row>
    <v-layout class="px-2 text-center mx-auto">
            <span class="caption text-center mx-auto">
                By signing up you agree to all our
                <router-link to="terms_conditions" class="font-weight-bold caption">
                    Terms & Conditions
                </router-link>
            </span>
        </v-layout>
            <v-divider class="mt-6 mb-2"></v-divider>
        <v-row justify="space-between" align="center" dense class="px-5">
            <span>
                already have an Account?
            </span>
            <v-btn text class="primary--text" @click="showLogin" style="cursor:pointer">Phone</v-btn>
        </v-row>
        </form>
    </div>
</template>

<script>
    export default {
        name: "Sign-Up",
        data() {
    return {
      phone_verification: false,
      phone: "",
      phoneObj: {},
      vcode: "",
      google_loading: false,
      facebook_loading: false
    };
  },
  mounted() {
    this.firebase.auth().useDeviceLanguage();
    window.recaptchaVerifier = new this.firebase.auth.RecaptchaVerifier("sign_btn", {
      size: "invisible",
      callback: function(response) {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        //onSignInSubmit();
        console.log("recapture solved");
      }
    });
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    signin_form() {
      return this.$store.getters.showLogin;
    },
    signup_form() {
      return this.$store.getters.showSignup;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    }
  },
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        this.$router.push("/dash/book");
      }
    }
  },
  methods: {
    userPhoneNo(payload) {
      console.log(payload);
      this.phoneObj = payload;
    },
    showLogin() {
      this.$store.commit("setLogin", true);
    },
    verifyPhone() {
      this.phone_verification = true;
    },
    closeVerify(){
      this.phone_verification = false;
      this.$store.commit('setLoading',false);
    },
    goHome() {
      this.$router.push("/");
    },
    login: function() {
      let self = this;
      if (this.phoneObj.isValid) {
        this.$store.commit('setLoading',true);
        let phoneNumber = this.phoneObj.e164;
        let appVerifier = window.recaptchaVerifier;
        this.firebase
          .auth()
          .signInWithPhoneNumber(phoneNumber, appVerifier)
          .then(function(confirmationResult) {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            window.confirmationResult = confirmationResult;
            console.log({ success: "good", confirmationResult });
            self.$store.commit('setLoading',false);
            self.phone_verification = true;
          })
          .catch(function(error) {
            self.$store.commit("setSnack", {
              color: "red",
              status_msg: "Error",
              added_msg: "Network Error."
            })
          }).
          finally(_=>{
            this.$store.commit('setLoading',false);
          })

      } else {
        self.$store.commit("setSnack", {
          color: "red",
          status_msg: "Error",
          added_msg: "Phone number is invalid."
        });
        this.phone_verification = false;
        console.log("Phone No. is invalid.");
      }
    },
    checkCode() {
      let code = this.vcode;
      let self = this;
      this.$store.commit('setLoading',true);
      //console.log(code);
      confirmationResult
        .confirm(code)
        // .then(function(result) {
        //   console.log("successfully login");
        //   // User signed in successfully.
        //   let user = result.user;
        //   let isNewUser = result.additionalUserInfo.isNewUser;
        //   console.log({ result: result });
        //   self.$store.commit('setLoading',false);
        //   self.phone_verification = false;
        //   if(isNewUser){
        //     self.$router.push('auth/auth_landing')
        //     self.$store.commit('setLoginState',false);
        //   }else{
        //     console.log(self.getToken())
        //     self.$store.commit('setLoginState',true);
        //   }
        // })
        .catch(function(error) {
          self.$store.commit('setLoading',false);
          self.$store.commit("setSnack", {
            color: "red",
            status_msg: "Error",
            added_msg:
              "Verification Code is wrong. Please check and send again."
          });
          console.log(error);
        });
    },
    googleLogin() {
      let self = this;
      let provider = new this.firebase.auth.GoogleAuthProvider();

      this.google_loading = true
      this.firebase
        .auth()
        .signInWithPopup(provider)
        .catch(error=>{
          // Handle Errors here.
          let errorCode = error.code;
          let errorMessage = error.message;
          // The email of the user's account used.
          let email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          let credential = error.credential;
          console.log({
            errorCode: errorCode,
            errorMessage: errorMessage,
            email: email,
            credential: credential
          });
          this.$store.commit('snackSet', {message: errorMessage, color: 'red'})
        }).finally(_=>{
          this.google_loading = false
        })
    },
    facebook_login: function() {
      let self = this;
      let provider = new this.firebase.auth.FacebookAuthProvider();


      this.facebook_loading = true
      this.firebase
        .auth()
        .signInWithPopup(provider)
        .catch(function(error) {
          // Handle Errors here.
          let errorCode = error.code;
          let errorMessage = error.message;
          // The email of the user's account used.
          let email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          let credential = error.credential;
          console.log({
            errorCode: errorCode,
            errorMessage: errorMessage,
            email: email,
            credential: credential
          })
          this.$store.commit('snackSet', {message: errorMessage, color: 'red'})
        }).finally(_=>{
          this.facebook_loading = true
        })
    },
    onDismissed() {
      console.log("dismissed");
      this.$store.dispatch("clearError");
    },
    getToken(){
      localStorage.removeItem('token');
      this.$store.dispatch('getToken')
              .then((response) =>{
                localStorage.setItem('token', response)
                console.log(response);
              })
              .catch(err => {
                self.loader = false;
                console.log(err)
              })
    },
  }
};
</script>

<style scoped>
    .google_color {
        color: #de5246;
    }
    .fup {
        border: 1px dashed rgba(0, 0, 0, 0.8);
        padding: 10px;
        border-radius: 10px;
    }
    .custom-loader {
        animation: loader 1s infinite;
        display: flex;
    }
    @-moz-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @-webkit-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @-o-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>
