import Vue from "vue";
import VueRouter from "vue-router";
import WebsiteRoutes from './WebsiteRoutes'
import AdminRoutes from './AdminRoutes'
import AgencyRoutes from './AgencyRoutes'

Vue.use(VueRouter);

const routes = [
    WebsiteRoutes,
    AdminRoutes,
    AgencyRoutes
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});
//
// router.afterEach((to, from) => {
//   if(Vue.prototype.$tidioChatApi){
//     if (to.matched.some((route) => route.meta.showChat)) {
//       Vue.prototype.$tidioChatApi.display(true)
//       console.log('showing')
//     }
//     else{
//       Vue.prototype.$tidioChatApi.display(false)
//       console.log('hiding')
//     }
//   }
//
//   console.log(to, Vue.prototype.$tidioChatApi, from)
//   // Vue.prototype.$tidioChatApi &&
//     // Vue.prototype.$tidioChatApi.display(to.meta.showChat);
// });

export default router;
