<template>
  <div>
    <!--    for mobile-->
    <v-app-bar
            v-if="isMobile"
            color="white"
            dense
            fixed
            app
      >
        <v-toolbar-title
                style="cursor:pointer"
                         class="ml-n2" @click="$router.push({name: 'home'})"
        >
          <v-img
                  src="@/assets/imgs/akoko_market_logo.png"
                  lazy-src="@/assets/imgs/akoko_market_logo.png"
          >

          </v-img>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      <v-menu

              :close-on-content-click="true"
              offset-y
              transition="scale-transition"
      >
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-badge
              right
              overlap
              color="red"
              >
                <span slot="badge">{{ noItems }}</span>
                  <v-icon
                          color="primary"
                          v-on="{ ...tooltip, ...menu }"
                          left>mdi-cart</v-icon>

              </v-badge>

            </template>
            <span>View cart items</span>
          </v-tooltip>
        </template>
        <user-cart></user-cart>
      </v-menu>

      <NotificationButton
        :allNotificationsLink="{name: 'notifications'}"
      ></NotificationButton>

      <!--              User Account Menu-->
      <v-menu bottom right offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on }">
          <v-btn
                  icon
              color="primary"
              rounded v-on="on">
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list dense>

            <v-list-item dense v-for="(item, i) in listo" :key="i" @click="item.click">
              <v-list-item-action>
              <v-icon :color="item.color">{{item.icon}}</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <v-btn
            class="elevation-4"
            small
            v-if="userData"
            @click="$router.push({name: 'agency-dashboard'})"
            color="primary"
            rounded>
        DASHBOARD
      </v-btn>
      <v-btn v-else
        dense
        dark
        class="primary mr-4"
        rounded
        @click="$router.push({name: 'sell-with-us'})">
          Sell
      </v-btn>

    </v-app-bar>


<!--    For large Screen-->

    <v-app-bar
      v-else
      app
      dense
      height="72"
      class="elevation-0 ma-3"
      style="border-radius:72px"
    >
        <v-row justify="space-between" align="center">
          <v-toolbar-title @click="$router.push({name: 'home'})" style="cursor:pointer" >
            <v-img src="@/assets/imgs/akoko_market_logo.png"
                  lazy-src="@/assets/imgs/akoko_market_logo.png"/>
          </v-toolbar-title>

         <!-- <custom-search class="mt-7"></custom-search> -->
          <!-- <auto-search class="mt-0"></auto-search> -->

          <v-toolbar-items>
            <v-row align="center" class="mx-0">
              <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-width="200"
                      offset-y
                      transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                      color="primary"
                      dark
                      v-on="on"
                      text
                      rounded=""
                  >
                    <v-icon left >mdi-information</v-icon>
                  Help
                  </v-btn>
                </template>

                <v-card>
                  <v-list>
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-icon>mdi-phone</v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-subtitle>Contact Us</v-list-item-subtitle>
                        <v-list-item-title class="caption">0553322008 / 0508538802</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                                icon
                        >
                          <v-icon class="grey--text text--darken1"
                                  @click="getToken">mdi-registered-trademark</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                </v-card>
              </v-menu>
              <!--CART DROPDOWN-->
              <v-menu
                      :close-on-content-click="true"
                      offset-y
                      transition="scale-transition"
              >
                <template v-slot:activator="{ on: menu }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                              color="primary"
                              v-on="{ ...tooltip, ...menu }"
                              text
                              rounded
                              dark>
                        <v-badge left color="red darken-2">
                          <span slot="badge">{{ noItems }}</span>
                          <v-icon left color=" ">
                            shopping_cart
                          </v-icon>
                        </v-badge>
                        CART
                      </v-btn>
                    </template>
                    <span>View cart items</span>
                  </v-tooltip>

                </template>
                <user-cart></user-cart>
              </v-menu>

              <NotificationButton></NotificationButton>


              <v-menu bottom right offset-y transition="slide-y-transition">
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="primary"
                    icon
                    rounded class="mr-3" v-on="on">
                    <v-icon>mdi-account-circle</v-icon>

                  </v-btn>
                </template>
                <v-card>
                  <v-list>
                    <v-list-item v-for="(item, i) in listo" :key="i" @click="item.click">
                      <v-list-item-action>
                        <v-icon :color="item.color">{{item.icon}}</v-icon>
                      </v-list-item-action>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
              <v-btn v-if="userData"
                  dark
                  class="primary mr-4"
                  rounded
                  @click="$router.push({name: 'agency-dashboard'})">
                  <v-icon left>mdi-home</v-icon>
                  Dashboard
              </v-btn>
              <v-btn v-else
                    dark
                    class="primary mr-4"
                    rounded
                    @click="$router.push({name: 'sell-with-us'})">
                  <v-icon left>mdi-cart</v-icon>
                  Sell with us
                </v-btn>
            </v-row>
          </v-toolbar-items>
        </v-row>
        </v-app-bar>

  </div>
</template>

<script>
// Utilities
import { mapMutations, mapGetters } from "vuex";
import CustomSearch from "@/components/Website/CustomSearch";
import AutoSearch from "@/components/Website/AutoSearch";
import SignUser from "@/components/Website/Customer/SignUser";
import UserCart from "@/components/Website/Customer/UserCart";
import NotificationButton from '@/components/notifications/notificationButton'

export default {
  name: "LoggedHeader",
  data() {
    return {
      menu:false,
      searchMenu:false,
      websiteToolbar: true,
      adminToolbar: false,
      listo: [
        // {
        //   icon: 'mdi-settings',
        //   title: 'Account Settings',
        //   color:'grey darken-1',
        //   click: this.account_settings
        // },
        // {
        //   icon: 'mdi-bell-outline',
        //   title: 'Notification Preferences',
        //   color:'grey darken-1',
        //   click: (e) => {
        //   }
        // },
        {
          icon: 'mdi-logout',
          title: 'Logout',
          color: 'red darken-1',
          click: this.logoutUser
        },
      ],
      trending_com:[
        {
          id:1,
          company:"Wilmar Farms",
          status:"Online",
          product:"Fresh Eggs, Broilers, Layers",
          location:"Adabraka",
          avatar: 'rooster.png'
        },{
          id:1,
          company:"Agro Innova Farms",
          status:"Online",
          product:"Fresh Eggs, Broilers, Layers",
          location:"Adabraka",
          avatar: 'rooster.png'
        },{
          id:1,
          company:"Boris B. Farms",
          status:"Online",
          product:"Fresh Eggs, Broilers, Layers",
          location:"Adabraka",
          avatar: 'rooster.png'
        },
      ],
      items: [
        { text: "Fresh Eggs", icon: " mdi-circle-small" },
        { text: "Local Poultry", icon: " mdi-circle-small" },
        { text: "Yellow Maize", icon: " mdi-circle-small" },
        { text: "Poultry Drugs", icon: " mdi-circle-small" },
        { text: "Poultry Equipment", icon: " mdi-circle-small" },
        { text: "Day Old Chicks", icon: " mdi-circle-small" },
        { text: "Poultry Feed", icon: " mdi-circle-small" },
        { text: "Feed Mill", icon: " mdi-circle-small" },
        { text: "Veterinary Services", icon: " mdi-circle-small" },
        { text: "Other Livestock", icon: " mdi-circle-small" },
      ],
    };
  },

  watch: {
    $route(val) {
      this.title = val.name;
    },
  },
  computed:{
    ...mapGetters(['loginState','cartMenu']),
    breakPoint(){
      return this.$vuetify.breakpoint
    },
    noItems(){
      return this.$store.getters.getCart.length;
    },
  },
  mounted() {
      // console.log(this.$vuetify.breakpoint)
      console.log(this.userData)
  },
  beforeDestroy() {},

  methods: {
    clearLoader(){
      this.$store.commit("setLoading",false);
    },
    getToken(){
      localStorage.removeItem('token');
      this.$store.dispatch('getToken')
              .then((response) =>{
                localStorage.setItem('token', response);
                alert(response);
                console.log(response);
              })
              .catch(err => {
                self.loader = false;
                console.log(err)
              })
    },
    logoutUser(){
      let self = this;
        this.$store.dispatch("auth/logoutUser").then(e=>{
          self.$store.commit('setSnack', { color: 'error', status_msg: 'Logged Out', added_msg: 'User Logged Out.' })
        });
    },
  },
  components:{
    CustomSearch,
    AutoSearch,
    SignUser,
    UserCart,
    NotificationButton
  }
};
</script>

<style>
#core-app-bar {
  width: auto;
}

.roundit{
  border-radius:40px 0px 0px 0px;
}

#core-app-bar a {
  text-decoration: none;
}
</style>
