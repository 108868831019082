import config from "../config";

export default {
  config: config,
  user_data: {},
  showLogin: true,
  cart_menu:false,
  loginState:false,
  loading: false,
  phone: "",
  showPhoneCode: false,
  companyDialog: false,
  createItemDialog: false,
  snackSet: {
    status_msg: "Success",
    added_msg: "Data successfully added.",
    color: "primary",
    top: true,
    left: true,
    bottom: false,
    right: false
  },

  activeCart:[
  ],
  categories: [
  {slug:"fresh_eggs", name: "Fresh Eggs", icon: " mdi-circle-small",
    attributes:[],message:"Get all your poultry eggs at farm gate price" },

  {slug:"local_chicken", name: "Local Poultry", icon: " mdi-circle-small",
    message:"Get your healthy broilers, cockerels, guinea fowl, turkey,  either live, dressed or full."},

  {slug:"maize", name: "Maize", icon: " mdi-circle-small",
    message:"Buy your quality yellow corn and white corn for your feed here " },

  {slug:"poultry_drugs", name: "Poultry Drugs", icon: " mdi-circle-small" ,
    attributes:[],message:"Get all your certified vitamins, antibiotics,vaccines here" },

    {slug:"poultry_equipment", name: "Poultry Equipment", icon:"mdi-circle-small",
      attributes:[],message:"Buy your feeders, drinkers, incubators, egg trays etc here"},

  {slug:"day_old_chicks", name: "Day Old Chicks", icon: " mdi-circle-small",
    attributes:[],message:"Get you quality local and foreign DOCs available for best stock performances."},

  {slug:"poultry_feed", name: "Poultry Feed", icon: " mdi-circle-small",
    attributes:[],message:"Get all your local eggs from here."},

  {slug:"feed_mill", name: "Feed Mill", icon: " mdi-circle-small",
    attributes:[],message:"Access your feedmills to prepare your animal feeds here."},

  {slug:"veterinary_services", name: "Veterinary Services", icon: " mdi-circle-small",
    attributes:[],message:"Quick link to a certified Veterinary Doctor, Officer, CAHWS and extension agents."},

  {slug:"other_livestock", name: "Other Livestock", icon: " mdi-circle-small",
    attributes:[],message:"Buy your Cattles, Pigs, Rabbits, Goats and other livestocks here"},
],
  products_dummy: [
      // maize
    {
      img_url:"assets/imgs/products/maize/1.png",
      lazy_url:"assets/imgs/products/maize/lazy/1.png",
      product_name:"Yellow Maize",
      product_type:"maize",
      agent_name:"Kosmos Foods LTD",
      price:23,
      rating:3.5,
      agent_id:1,
      agent_location:"Accra New Town, Bawaleshie",
      agent_contact:"0247759839",
      product_details:[
        {
          weight:"500KG",
        }
      ],
    },{
      img_url:"assets/imgs/products/maize/2.png",
      lazy_url:"assets/imgs/products/maize/lazy/2.png",
      product_name:"Yellow Maize",
      product_type:"maize",
      agent_name:"Kosmos Foods LTD",
      price:23,
      rating:3.5,
      agent_id:1,
      agent_location:"Accra New Town, Bawaleshie",
      agent_contact:"0247759839",
      product_details:[
        {
          weight:"500KG",
        }
      ],
    },{
      img_url:"assets/imgs/products/maize/3.png",
      lazy_url:"assets/imgs/products/maize/lazy/3.png",
      product_name:"Yellow Maize",
      product_type:"maize",
      agent_name:"Kosmos Foods LTD",
      price:23,
      rating:3.5,
      agent_id:1,
      agent_location:"Accra New Town, Bawaleshie",
      agent_contact:"0247759839",
      product_details:[
        {
          weight:"500KG",
        }
      ],
    },
    {
      img_url:"assets/imgs/products/maize/4.png",
      lazy_url:"assets/imgs/products/maize/lazy/4.png",
      product_name:"Yellow Maize",
      product_type:"maize",
      agent_name:"Kosmos Foods LTD",
      price:23,
      rating:3.5,
      agent_id:1,
      agent_location:"Accra New Town, Bawaleshie",
      agent_contact:"0247759839",
      product_details:[
        {
          weight:"500KG",
        }
      ],
    },{
      img_url:"assets/imgs/products/maize/1.png",
      lazy_url:"assets/imgs/products/maize/lazy/1.png",
      product_name:"Yellow Maize",
      product_type:"maize",
      agent_name:"Kosmos Foods LTD",
      price:23,
      rating:3.5,
      agent_id:1,
      agent_location:"Accra New Town, Bawaleshie",
      agent_contact:"0247759839",
      product_details:[
        {
          weight:"500KG",
        }
      ],
    },

      // Fresh Eggs
    {
      img_url:"assets/imgs/products/fresh_eggs/1.png",
      lazy_url:"assets/imgs/products/fresh_eggs/lazy/1.png",
      product_name:"Fresh Eggs",
      agent_name:"Boris Farms LTD",
      product_type:"fresh_eggs",
      rating:3.5,
      agent_id:1,
      price:300,
      agent_location:"Accra New Town, Bawaleshie",
      agent_contact:"0247759839",
      product_details:[
        {
          weight:"500KG",
        }
      ],
    },{
      img_url:"assets/imgs/products/fresh_eggs/2.png",
      lazy_url:"assets/imgs/products/fresh_eggs/lazy/2.png",
      product_name:"Fresh Eggs",
      agent_name:"Boris Farms LTD",
      product_type:"fresh_eggs",
      rating:3.5,
      agent_id:1,
      price:300,
      agent_location:"Accra New Town, Bawaleshie",
      agent_contact:"0247759839",
      product_details:[
        {
          weight:"500KG",
        }
      ],
    },{
      img_url:"assets/imgs/products/fresh_eggs/3.png",
      lazy_url:"assets/imgs/products/fresh_eggs/lazy/3.png",
      product_name:"Fresh Eggs",
      agent_name:"Boris Farms LTD",
      product_type:"fresh_eggs",
      rating:3.5,
      agent_id:1,
      price:300,
      agent_location:"Accra New Town, Bawaleshie",
      agent_contact:"0247759839",
      product_details:[
        {
          weight:"500KG",
        }
      ],
    },{
      img_url:"assets/imgs/products/fresh_eggs/4.png",
      lazy_url:"assets/imgs/products/fresh_eggs/lazy/4.png",
      product_name:"Fresh Eggs",
      agent_name:"Boris Farms LTD",
      product_type:"fresh_eggs",
      rating:3.5,
      agent_id:1,
      price:300,
      agent_location:"Accra New Town, Bawaleshie",
      agent_contact:"0247759839",
      product_details:[
        {
          weight:"500KG",
        }
      ],
    },{
      img_url:"assets/imgs/products/fresh_eggs/5.png",
      lazy_url:"assets/imgs/products/fresh_eggs/lazy/5.png",
      product_name:"Fresh Eggs",
      agent_name:"Boris Farms LTD",
      product_type:"fresh_eggs",
      rating:3.5,
      agent_id:1,
      price:300,
      agent_location:"Accra New Town, Bawaleshie",
      agent_contact:"0247759839",
      product_details:[
        {
          weight:"500KG",
        }
      ],
    },

      // Local Poultry
    {
      img_url:"assets/imgs/products/local_chicken/1.png",
      lazy_url:"assets/imgs/products/local_chicken/lazy/1.png",
      product_name:"Local Poultry",
      agent_name:"Agroinnova Farms",
      product_type:"local_chicken",
      rating:3.5,
      agent_id:1,
      price:300,
      agent_location:"Accra New Town, Bawaleshie",
      agent_contact:"0247759839",
      product_details:[
        {
          weight:"500KG",
        }
      ],
    },
    {
      img_url:"assets/imgs/products/local_chicken/2.png",
      lazy_url:"assets/imgs/products/local_chicken/lazy/2.png",
      product_name:"Local Poultry",
      agent_name:"Agroinnova Farms",
      product_type:"local_chicken",
      rating:3.5,
      agent_id:1,
      price:300,
      agent_location:"Accra New Town, Bawaleshie",
      agent_contact:"0247759839",
      product_details:[
        {
          weight:"500KG",
        }
      ],
    },{
      img_url:"assets/imgs/products/local_chicken/3.png",
      lazy_url:"assets/imgs/products/local_chicken/lazy/3.png",
      product_name:"Local Poultry",
      agent_name:"Agroinnova Farms",
      product_type:"local_chicken",
      rating:3.5,
      agent_id:1,
      price:300,
      agent_location:"Accra New Town, Bawaleshie",
      agent_contact:"0247759839",
      product_details:[
        {
          weight:"500KG",
        }
      ],
    },{
      img_url:"assets/imgs/products/local_chicken/4.png",
      lazy_url:"assets/imgs/products/local_chicken/lazy/4.png",
      product_name:"Local Poultry",
      agent_name:"Agroinnova Farms",
      product_type:"local_chicken",
      rating:3.5,
      agent_id:1,
      price:300,
      agent_location:"Accra New Town, Bawaleshie",
      agent_contact:"0247759839",
      product_details:[
        {
          weight:"500KG",
        }
      ],
    },{
      img_url:"assets/imgs/products/local_chicken/5.png",
      lazy_url:"assets/imgs/products/local_chicken/lazy/5.png",
      product_name:"Local Poultry",
      agent_name:"Agroinnova Farms",
      product_type:"local_chicken",
      rating:3.5,
      agent_id:1,
      price:300,
      agent_location:"Accra New Town, Bawaleshie",
      agent_contact:"0247759839",
      product_details:[
        {
          weight:"500KG",
        }
      ],
    },{
      img_url:"assets/imgs/products/local_chicken/6.png",
      lazy_url:"assets/imgs/products/local_chicken/lazy/6.png",
      product_name:"Local Poultry",
      agent_name:"Agroinnova Farms",
      product_type:"local_chicken",
      rating:3.5,
      agent_id:1,
      price:300,
      agent_location:"Accra New Town, Bawaleshie",
      agent_contact:"0247759839",
      product_details:[
        {
          weight:"500KG",
        }
      ],
    },
      // Poultry Drugs
    {
      img_url:"assets/imgs/products/local_chicken/2.png",
      lazy_url:"assets/imgs/products/local_chicken/lazy/2.png",
      product_name:"Local Poultry",
      agent_name:"Agroinnova Farms",
      product_type:"local_chicken",
      rating:3.5,
      agent_id:1,
      price:300,
      agent_location:"Accra New Town, Bawaleshie",
      agent_contact:"0247759839",
      product_details:[
        {
          weight:"500KG",
        }
      ],
    },
    {
      img_url:"assets/imgs/products/poultry_drugs/1.png",
      lazy_url:"assets/imgs/products/poultry_drugs/lazy/1.png",
      product_name:"Poultry Drugs",
      agent_name:"Agroinnova Farms",
      product_type:"poultry_drugs",
      rating:3.5,
      agent_id:1,
      price:300,
      agent_location:"Accra New Town, Bawaleshie",
      agent_contact:"0247759839",
      product_details:[
        {
          weight:"500KG",
        }
      ],
    },{
      img_url:"assets/imgs/products/poultry_drugs/2.png",
      lazy_url:"assets/imgs/products/poultry_drugs/lazy/2.png",
      product_name:"Poultry Drugs",
      agent_name:"Agroinnova Farms",
      product_type:"poultry_drugs",
      rating:3.5,
      agent_id:1,
      price:300,
      agent_location:"Accra New Town, Bawaleshie",
      agent_contact:"0247759839",
      product_details:[
        {
          weight:"500KG",
        }
      ],
    },{
      img_url:"assets/imgs/products/poultry_drugs/3.png",
      lazy_url:"assets/imgs/products/poultry_drugs/lazy/3.png",
      product_name:"Poultry Drugs",
      agent_name:"Agroinnova Farms",
      product_type:"poultry_drugs",
      rating:3.5,
      agent_id:1,
      price:300,
      agent_location:"Accra New Town, Bawaleshie",
      agent_contact:"0247759839",
      product_details:[
        {
          weight:"500KG",
        }
      ],
    },


    // Day old Chicks
    {
      img_url:"assets/imgs/products/day_old_chicks/1.png",
      lazy_url:"assets/imgs/products/day_old_chicks/lazy/1.png",
      product_name:"Day Old Chicks",
      agent_name:"Agroinnova Farms",
      product_type:"day_old_chicks",
      rating:3.5,
      agent_id:1,
      price:300,
      agent_location:"Accra New Town, Bawaleshie",
      agent_contact:"0247759839",
      product_details:[
        {
          weight:"500KG",
        }
      ],
    },{
      img_url:"assets/imgs/products/day_old_chicks/2.png",
      lazy_url:"assets/imgs/products/day_old_chicks/lazy/2.png",
      product_name:"Local Poultry",
      agent_name:"Agroinnova Farms",
      product_type:"local_chicken",
      rating:3.5,
      agent_id:1,
      price:300,
      agent_location:"Accra New Town, Bawaleshie",
      agent_contact:"0247759839",
      product_details:[
        {
          weight:"500KG",
        }
      ],
    },{
      img_url:"assets/imgs/products/day_old_chicks/2.png",
      lazy_url:"assets/imgs/products/day_old_chicks/lazy/2.png",
      product_name:"Day old chicks",
      agent_name:"Agroinnova Farms",
      product_type:"day_old_chicks",
      rating:3.5,
      agent_id:1,
      price:300,
      agent_location:"Accra New Town, Bawaleshie",
      agent_contact:"0247759839",
      product_details:[
        {
          weight:"500KG",
        }
      ],
    },{
      img_url:"assets/imgs/products/day_old_chicks/3.png",
      lazy_url:"assets/imgs/products/day_old_chicks/lazy/3.png",
      product_name:"Local Poultry",
      agent_name:"Agroinnova Farms",
      product_type:"day_old_chicks",
      rating:3.5,
      agent_id:1,
      price:300,
      agent_location:"Accra New Town, Bawaleshie",
      agent_contact:"0247759839",
      product_details:[
        {
          weight:"500KG",
        }
      ],
    },{
      img_url:"assets/imgs/products/day_old_chicks/4.png",
      lazy_url:"assets/imgs/products/day_old_chicks/lazy/4.png",
      product_name:"Day Old Chicks",
      agent_name:"Agroinnova Farms",
      product_type:"day_old_chicks",
      rating:3.5,
      agent_id:1,
      price:300,
      agent_location:"Accra New Town, Bawaleshie",
      agent_contact:"0247759839",
      product_details:[
        {
          weight:"500KG",
        }
      ],
    },{
      img_url:"assets/imgs/products/day_old_chicks/5.png",
      lazy_url:"assets/imgs/products/day_old_chicks/lazy/5.png",
      product_name:"Day Old Chicks",
      agent_name:"Agroinnova Farms",
      product_type:"day_old_chicks",
      rating:3.5,
      agent_id:1,
      price:300,
      agent_location:"Accra New Town, Bawaleshie",
      agent_contact:"0247759839",
      product_details:[
        {
          weight:"500KG",
        }
      ],
    },
    {
      img_url:"assets/imgs/products/maize/2.png",
      lazy_url:"assets/imgs/products/maize/lazy/2.png",
      product_name:"Yellow Maize",
      agent_name:"Agroinnova Farms",
      product_type:"maize",
      rating:3.5,
      agent_id:1,
      price:300,
      agent_location:"Accra New Town, Bawaleshie",
      agent_contact:"0247759839",
      product_details:[
        {
          weight:"500KG",
        }
      ],
    },
    {
      img_url:"assets/imgs/products/other_livestock/2.png",
      lazy_url:"assets/imgs/products/maize/lazy/2.png",
      product_name:"Other Livestock",
      agent_name:"Agroinnova Farms",
      product_type:"other_livestock",
      rating:3.5,
      agent_id:1,
      price:300,
      agent_location:"Accra New Town, Bawaleshie",
      agent_contact:"0247759839",
      product_details:[
        {
          weight:"500KG",
        }
      ],
    },
  ]
};
