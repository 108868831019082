import {gql} from 'apollo-boost'

const fragments = `
{
    id
    phone
    phone_operator
    cost
    status
    location{location}
    customer{
        id
        first_name
    }
    company{
        id
        name
    }
    products{
        id
        product{
            name
            price
            weight
            quantity
            company{
                name
                phone
                location
            }
            category{
                name
            }
        }
        qty
    }
    createdAt
}
`

const fragmentByCompany =  (filter)=>{
    console.log(filter)
    return `
    {
        id
        phone
        phone_operator
        cost
        status
        location{location}
        customer{
            id
            first_name
        }
        company{
            id
            name
        }
        products(where : {product : {company: {id : "${filter}"}}}){
            id
            product{
                name
                price
                weight
                quantity
                company{
                    name
                    phone
                }
                category{
                    name
                }
            }
            qty
        }
        createdAt
    }
    `
}

export const create = gql`
    mutation  createMarketRequest($data: MarketRequestCreateInput!){
        createMarketRequest(data: $data)${fragments}
    }
`

export const get = gql`
    query marketRequests($where: MarketRequestWhereInput){
        marketRequests(where: $where orderBy: createdAt_DESC)${fragments}
    }
`

export const update = gql`
    mutation updateMarketRequest($where: MarketRequestWhereUniqueInput! $data: MarketRequestUpdateInput!){
        updateMarketRequest(data: $data where: $where)${fragments}
    }
`

export const remove = gql`
    mutation deleteMarketRequest($where: MarketRequestWhereUniqueInput!){
        deleteMarketRequest(where: $where)${fragments}
    }
`

export const subscribe = gql`
    subscription marketRequest($where: MarketRequestSubscriptionWhereInput){
        marketRequest(where: $where){
            mutation
            node${fragments}
        }
    }
`

export const getByCompany = id => gql`
    query marketRequests($where: MarketRequestWhereInput){
        marketRequests(where: $where orderBy: createdAt_DESC)${fragmentByCompany(id)}
    }
`

export const subscribeByCompany = id => gql`
    subscription marketRequest($where: MarketRequestSubscriptionWhereInput ){
        marketRequest(where: $where orderBy: createdAt_DESC){
            mutation
            node${fragmentByCompany(id)}
        }
    }
`
