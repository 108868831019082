<template>
    <v-row class="px-8">
        
            <v-autocomplete
                    v-if="type === 'mini'"
                    @keypress.enter.prevent="searchForm"
                    @select="selected"
                    class="elevation-0"
                    v-model="model"
                    :items="items"
                    :loading="isLoading"
                    :search-input.sync="search"
                    clearable
                    hide-details
                    hide-selected
                    item-text="name"
                    item-value="id"
                    placeholder="search for a product here"
                    height="50px"
                    dense
                    solo
                    background-color="white"
                    flat
                    append-icon=""
                    style="border: rgb(159, 224, 159) 1px solid; color: grey;"
            >
                <template v-slot:append>
                    <v-icon color="grey" size="32">mdi-magnify</v-icon>
                </template>
                <template v-slot:no-data>
                    <v-list-item>
                        <v-list-item-title>
                            Find Maize, Soya, Egg, Chicken etc....
                        </v-list-item-title>
                    </v-list-item>
                </template>
                <template v-slot:item="{ item }">
                    <v-list-item-avatar
                            color="white"
                            class="headline font-weight-light white--text"
                    >
                        <v-img
                                :src="`${imgHost}imgs/products/${item.product_type.slug}/${item.img_url}?w=60`"
                                :lazy-src="`${imgHost}imgs/products/${item.product_type.slug}/${item.img_url}?w=8`"
                        ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-bold" v-text="item.name"></v-list-item-title>
                        <v-list-item-subtitle>{{item.product_type.name}}@<i class="caption red--text">{{item.company.name}}</i></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <i class="green--text text--darken-2">{{item.price | currency}}</i>
                    </v-list-item-action>
                </template>
            </v-autocomplete>
            <v-autocomplete
                    v-else
                    @keypress.enter.prevent="searchForm"
                    @select="selected"
                    class="elevation-0 rounded-lg"
                    v-model="model"
                    :items="items"
                    :loading="isLoading"
                    :search-input.sync="search"
                    clearable
                    hide-details
                    hide-selected
                    item-text="name"
                    item-value="id"
                    placeholder="Search for a product here"
                    height="60px"
                    dense
                    solo
                    flat
                    append-icon=""
                    style="border:rgb(159, 224, 159) 1px solid; font-size: medium;"
            >
                <template v-slot:append>
                    <v-icon color="grey" size="40">mdi-magnify</v-icon>
                </template>
                <template v-slot:no-data>
                    <v-list-item>
                        <v-list-item-title>
                            Find Maize, Soya, Chicken, Eggs etc....
                        </v-list-item-title>
                    </v-list-item>
                </template>
                <template v-slot:item="{ item }">
                    <v-list-item-avatar
                            color="white"
                            class="headline font-weight-light white--text"
                    >
                        <v-img
                                :src="`${imgHost}imgs/products/${item.product_type.slug}/${item.img_url}?w=60`"
                                :lazy-src="`${imgHost}imgs/products/${item.product_type.slug}/${item.img_url}?w=8`"
                        ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-bold" v-text="item.name"></v-list-item-title>
                        <v-list-item-subtitle>{{item.product_type.name}}@<i class="caption red--text">{{item.company.name}}</i></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <i class="green--text text--darken-2">{{item.price | currency}}</i>
                    </v-list-item-action>
                </template>
            </v-autocomplete>
        
    </v-row>
</template>

<script>
    export default {
        data: () => ({
            isLoading: false,
            items: [],
            model: null,
            search: null,
            tab: null,
        }),

        props: {
            type: String
        },

        watch: {
            model (val) {
              let selectProduct = this.items.filter(e=>{
                  return e.id === val;
              })
                console.log({selected:selectProduct});
              this.$store.commit('setFoundProduct', selectProduct[0]);
                this.$router.push(`/search_landing/${val}`);
            },
            select(val){

            },
            search (val){
                // Items have already been loaded
                if (this.items.length > 0) return
                this.isLoading = true;
                // Lazily load input items
                this.$store.dispatch("searchProducts",val)
                    // .then(res => res.json())
                    .then(res => {
                        this.items = res.data.products;
                    })
                    .catch(err => {
                        console.log(err)
                    })
                    .finally(() => (this.isLoading = false))
            },
        },
        methods:{
            searchForm(){
                this.$router.push({name: 'products-search', params: {search: this.search}})
            },

            selected(){
                // alert("pooped");
            },
        }
    }
</script>

<style scoped>

</style>
