import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import config from "./config.js";
import AlertCmp from './components/Website/Alert'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import 'vue2-animate/dist/vue2-animate.min.css'
import "@/assets/vendor/animate.css/animate.min.css"

import moment from 'vue-moment'
Vue.use(moment)

import VueObserveVisibility from 'vue-observe-visibility'
Vue.use(VueObserveVisibility)

// import VueTidio from 'vue-tidio'
// Vue.use(VueTidio, { appKey: config.tidio_key })

// Firebase initialization
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/analytics'
const configOptions = config.firebase;
firebase.initializeApp(configOptions);

Vue.prototype.firebase = firebase;


// Apollo Initialization
import { ApolloClient } from 'apollo-client'
import VueApollo from 'vue-apollo'


import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'

//Subscription base imports
import { split } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'

//Http GraphQL link
const httpLink = new createHttpLink({
  uri: config.graphqlUri,
});

//WebSocket Links Config
const wsLink = new WebSocketLink({
  uri: config.graphqlWsUri,
  options: {
    reconnect: true,
  },
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});


// Splitting links between http and ws
const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
    },
    wsLink,
    httpLink
)

const defaultOptions =  {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}


const cache = new InMemoryCache()
export const apolloClient = new ApolloClient({
  link: authLink.concat(link),
  cache,
  connectToDevTools: true,
  uri: config.graphqlUri,
  defaultOptions: defaultOptions,
})

// Cache implementation
const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})

import AppAxios from './plugins/axios'

import vuetify from "./plugins/vuetify";
import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import "./registerServiceWorker";

import VueCurrencyFilter from 'vue-currency-filter'

Vue.use(AppAxios);
Vue.use(VueApollo);
Vue.use(VueCurrencyFilter,
{
  symbol : '‎GH₵',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: true
})

Vue.config.productionTip = false;

// Component Declaration
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('app-alert',AlertCmp);
Vue.component('akoko-phone', VuePhoneNumberInput);


export const eventBus = new Vue();
import {mapGetters} from "vuex";

// Global Mixins

Vue.mixin({
  computed:{
    userData(){
      return this.$store.getters['auth/getUserData'];
    },

    imgHost(){
      return this.$store.getters.getImgHost;
    },
    apiSet(){
      return this.$store.getters.getApi
    },
    currency(){
      return "GH₵"; //Will return global currency symbols in the future
    },
    isMobile(){
      return this.$vuetify.breakpoint.mdAndDown
    },
    isSmallScreen(){
      return this.$vuetify.breakpoint.xsOnly
    }
  },
  methods:{
    $message(message = '', sub_message = '', icon = '', color = ''){
      this.$store.commit('setSnack', {
        color: color || 'info',
        icon: icon || 'mdi-information',
        status_msg: message,
        added_msg: sub_message
      })
    },
    $error(message = '', sub_message = ''){
      this.$message(message, sub_message, 'mdi-alert', 'error')
    },
    $success(message = '', sub_message = ''){
      this.$message(message, sub_message, 'mdi-check', 'green')
    },
  }
});

store.firebase = firebase;


new Vue({
  router,
  store,
  vuetify,
  apolloProvider,
  render: h => h(App)
}).$mount("#app");


