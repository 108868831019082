/**
 * Vuex
 *
 * @library
 *
 * https://vuex.vuejs.org/en/
 */
import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";


const vuexLocalStorage = new VuexPersist({
  key: "vuex", // The key to store the state on in the storage provider.
  storage: window.localStorage,// or window.sessionStorage or localForage
  modules: ['auth','products']
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

// Store functionality
import actions from "./actions";
import getters from "./getters";
import modules from "./modules";
import mutations from "./mutations";
import state from "./state";

Vue.use(Vuex);

// Create a new store

const store = new Vuex.Store({
  actions,
  getters,
  modules,
  mutations,
  state,
  plugins: [vuexLocalStorage.plugin]
});

export default store;


