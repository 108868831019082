<template>
  <!-- <v-flex xs12 sm12 > -->
    <v-card width="900px" rounded="xl" height="100%" class="mt-1 pa-4">
      <div class="d-flex justify-end">
        <v-btn @click="$emit('hideDialog')" icon>
        <v-icon color="red">mdi-close</v-icon>
      </v-btn>
      </div>
      <v-toolbar color="white" flat dense>
        <v-toolbar-title class="text-xs-center font-weight-bold">My Cart Items</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-badge left>
          <span slot="badge">{{ noItems }}</span>
          <v-icon color=" lighten-1">
            shopping_cart
          </v-icon>
        </v-badge>
      </v-toolbar>
        <v-list v-if="isSmallScreen" dense>
            <v-list-item
                    class="py-n4 d-flex justify-center align-center"
                    v-for="item in displayCart"
                    :key="item.id"
                    avatar 
            >

               <div style="width: 30vw">
                  <v-list-item-content id="iden_small" >
                    <v-list-item-title id="name" class="primary--text" v-text="item.name"></v-list-item-title>
                    <v-list-item-title  id="size" class="grey--text" v-text="item.weight && item.units? `${item.weight} ${item.units}` : `` "></v-list-item-title>
                </v-list-item-content>
               </div>
                <v-list-item-content class="cart_details_small text-center" >
                    <p v-text="item.purchase_quantity"></p>
                    <div class="small grey--text">quantity</div>
                </v-list-item-content>
                <v-list-item-content class="cart_details_small text-center" >
                    <p v-text="`${currency} ${item.price}`"></p>
                    <div>Unit Price</div>
                </v-list-item-content>
                <v-list-item-content class="cart_details_small text-center" >
                    <p v-text="`${currency}${item.subtotal}`"></p>
                    <div>Subtotal</div>
                </v-list-item-content>
    
                <div class="d-flex justify-center align-center">
                  <v-list-item-icon class="mr-0">    
                    <v-icon small @click="removeItem(item)">mdi-close-circle</v-icon>
                  </v-list-item-icon>
                </div>
            </v-list-item>
        </v-list>
        <v-list v-else dense>
            <v-list-item
                    class="py-n4 d-flex justify-center align-center"
                    v-for="item in displayCart"
                    :key="item.id"
                    avatar 
            >
                <v-card elevation="0" style="border-radius: 20px;" >
                    <v-img
                            :src="`${imgHost}imgs/products/${item.product_type.slug}/${item.img_url}?w=100`"
                            height="55px"
                            width="80px"
                            style="position: relative"
                    ></v-img>
                </v-card>

                <div style="width: 32%">
                  <v-list-item-content id="iden" >
                    <v-list-item-title id="name" class="primary--text" v-text="item.name"></v-list-item-title>
                    <v-list-item-title  id="size" class="grey--text" v-text="item.weight && item.units? `${item.weight} ${item.units}` : `` "></v-list-item-title>
                </v-list-item-content>
                </div>
                <v-list-item-content class="cart_details text-center" >
                    <p v-text="item.purchase_quantity"></p>
                    <div class="small grey--text">quantity</div>
                </v-list-item-content>
                <v-list-item-content class="cart_details text-center" >
                    <p v-text="`${currency} ${item.price}`"></p>
                    <div>Unit Price</div>
                </v-list-item-content>
                <v-list-item-content class="cart_details text-center" >
                    <p v-text="`${currency}${item.subtotal}`"></p>
                    <div>Subtotal</div>
                </v-list-item-content>
    
              <div class="d-flex justify-center align-center">
                <v-list-item-icon class="mr-0">    
                  <v-icon small @click="removeItem(item)">mdi-close-circle</v-icon>
                </v-list-item-icon>
              </div>
            </v-list-item>
        </v-list>

      <v-divider></v-divider>

        <table class="font-weight-regular px-4" style="text-align:center">
          <tr style="text-align:start">
            <td style="font-weight:500">Order Total:</td>
            <td style="font-weight:500" class="align-start">{{ totalCost | currency }}</td>
          </tr>
          <tr>
            <td style="font-weight:700">Amount Due:</td>
            <td style="font-weight:700" class="align-start">{{ totalCost | currency }}</td>
          </tr>
        </table>
        <v-list-item-content align="center" class="ml-4 px-0">
          <v-btn style="color:white; text-transform: capitalize" elevation="0" :disabled="!noItems" class="red" @click="goCheckout" rounded>
            Proceed 
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-list-item-content>
    </v-card>
  <!-- </v-flex> -->
</template>
<script>
export default {
  name: "UserCart",
  data() {
    return {
      items2: [
        {
          title: "Travis Howard",
          avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg"
        }
      ]
    };
  },
  computed: {

      upCart() {
      return this.$store.getters.getCart;
    },
    noItems() {
      return this.upCart.length;
    },
    totalCost() {
       let cost =  Object.values(this.upCart).reduce(
        (t, n) => t + parseFloat(n.subtotal), 0);
        return cost.toFixed(2);
    },
      displayCart() {
          return this.$store.getters.getCart.slice(0,4);
      },
  },
  methods: {
    goCheckout() {
      this.$emit('hideDialog')
      this.$router.push("/cart");
    },
    removeItem(item) {
      let index = this.upCart.indexOf(item);
      this.upCart.splice(index, 1);
      this.$router.push('/cart');
    }
  }
};
</script>

<style lang="scss">
.pricing {
  font-weight: bold;
}

.cart_details {
  p{ 
    font-size: 18px; 
    font-weight: 400; 
    line-height: 25px;
    color: black;
  }
  div {
    font-size: 13px; 
    font-weight: 400; 
    line-height: 18px;
    color: gray;
  }
}

.cart_details_small {
  p{ 
    font-size: 12px; 
    font-weight: 400; 
    line-height: 15px;
    color: black;
  }
  div {
    font-size: 10px; 
    font-weight: 400; 
    line-height: 13px;
    color: gray;
  }
}

#iden {
  padding-left: 10px;
  #name {
    font-size: 27px; 
    font-weight: 600; 
    line-height: 33px;
  }
  #size {
    font-size: 15px; 
    font-weight: 400; 
    line-height: 18px;
  }
}

#iden_small {
  padding-left: 10px;
  #name {
    font-size: 18px; 
    font-weight: 600; 
    line-height: 23px;
  }
  #size {
    font-size: 10px; 
    font-weight: 400; 
    line-height: 13px;
  }
}

.pricing td:nth-last-child(1) {
  color: green;
  padding: 2px;
}
</style>
