<template>
    <validation-observer
        ref="observer"
        v-slot="{ invalid }"
    >
      <form @submit.prevent="submitForm">
        <validation-provider
            v-slot="{ errors }"
            name="name"
            rules="required|max:30"
        >
          <v-text-field
              v-model="formModel.name"
              :error-messages="errors"
              label="Name"
              required
          ></v-text-field>
        </validation-provider>
        <validation-provider
            v-slot="{ errors }"
            name="phoneNumber"
            :rules="{
          required: true,
          max: 13,
          regex: '^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{4})(?: *x(\\d+))?\\s*$'
        }"
        >
          <v-text-field
              v-model="formModel.phoneNumber"
              :error-messages="errors"
              label="Phone Number"
              required
          ></v-text-field>
        </validation-provider>
        <validation-provider
            v-slot="{ errors }"
            name="product"
            rules="required"
        >
          <v-autocomplete
              v-model="formModel.product"
              :error-messages="errors"
              label="Product"
              :items="productCategories"
              @change="getSelected"
              item-text="name"
              item-value="id"
              return-object
              required
          ></v-autocomplete>
        </validation-provider>
        <validation-provider
            v-slot="{ errors }"
            name="price"
            rules="required|max:30"
        >
          <v-text-field
              v-model="formModel.price"
              :error-messages="errors"
              label="Price"
              required
          ></v-text-field>
        </validation-provider>
        <validation-provider
            v-slot="{ errors }"
            name="quantity"
            rules="required"
        >
          <v-text-field
              v-model="formModel.quantity"
              :error-messages="errors"
              label="Quantity"
              required
              type="number"
          ></v-text-field>
        </validation-provider>
        <validation-provider
            v-slot="{ errors }"
            name="region"
            rules="required"
        >
          <v-autocomplete
              v-model="formModel.region"
              :error-messages="errors"
              label="Region"
              :items="getRegions"
              item-text="name"
              item-value="id"
              required
          ></v-autocomplete>
        </validation-provider>
        <validation-provider
            v-slot="{ errors }"
            name="location"
            rules="required|max:30"
        >
          <v-text-field
              v-model="formModel.location"
              :error-messages="errors"
              label="Location"
              required
          ></v-text-field>
        </validation-provider>
        <v-btn
            class="mr-4"
            type="submit"
            :disabled="invalid"
            color="primary"
            elevation="0"
        >
          submit
        </v-btn>
        <v-btn
            outlined
            color="error"
            @click="clear"
        >
          clear
        </v-btn>
      </form>
    </validation-observer>
</template>

<script>
import {extend, setInteractionMode, ValidationObserver, ValidationProvider} from "vee-validate";
import {required, digits, email, regex, max} from "vee-validate/dist/rules";
import {firestore} from "firebase";
import store from "@/store";

import mixpanel from "mixpanel-browser";

mixpanel.init("6a7537bf8311e4fc76404e3362906a58", { debug: true, track_pageview: true });

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

extend('digits', {
  ...digits,
  message: '{_field_} needs to be {length} digits. ({_value_})'
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters',
})

extend('email', {
  ...email,
  message: 'Email must be valid',
})

extend('regex', {
  ...regex,
  message: '{_field_} {_value_} does not match {regex}',
})
export default {
  name: "SellForm",
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      formModel: {
        name: '',
        phoneNumber: '',
        email: '',
        product: null,
        region: '',
        price: '',
        quantity: '',
      },
      productCategories: [],
      selectedProductCategory: null,
      regions: [
          'Accra',
          'Brong Ahafo',
          'Central',
          'Eastern',
          'Western'
      ]
    }
  },
  computed: {
    getRegions() {
      let newCategories = this.productCategories;
      let regCategory = newCategories.filter((el) => el.id === this.selectedProductCategory.id)[0]
      if(!regCategory){
        return this.regions
      }
      else {
        return regCategory.regions
      }
    },
  },

  methods: {
    clear(){
      this.formModel = {}
    },
    getSelected(selected){
      console.log('...selected', selected)
      this.selectedProductCategory = selected
    },

    // this.phoneObj.formattedNumber.replace('+', "")
    submitForm(){
      mixpanel.track("Submit Join us farmer", {
        location: this.formModel.location,
        phoneNumber: this.formModel.phoneNumber,
        product: this.formModel.product
      })
      this.$refs.observer.validate()
          .then(
              () => {
                firestore().collection("requests").doc().set({
                  client_location: this.formModel.location,
                  client_name: this.formModel.name,
                  client_phone: this.formModel.phoneNumber.replace("+", "").replace(/^0/, '233').replaceAll(" ", ""),
                  client_quantity: this.formModel.quantity,
                  region: this.formModel.region,
                  source: "e-commerce",
                  network_provider: "",
                  completed: true,
                  category: {
                    ...this.formModel.product,
                  } ,
                  // product: { ...item },
                  product: {
                    ...this.product,
                    unit_price: this.formModel.price,
                    total: this.formModel.qty * this.formModel.unit_price,
                  },
                  service: {
                    acceptDetails: true,
                    enabled: true,
                    id: "zhuNNxcggIqg8oEFeqH1",
                    index: "1",
                    name: "Sell",
                  },
                  session_id: "",
                  created_at: new Date(),
                  updated_at: new Date(),
                })
                    .finally(() => {
                      this.clear()
                      this.$emit('close-dialog')
                      store.commit('setSnack', {
                        color: 'green',
                        status_msg: 'Success',
                        added_msg: 'Request has been successfully sent.',
                        icon: 'mdi-information'
                      })
                    })
              }
          )
          .catch((error) => {
            store.commit('setSnack', {
              color: 'red',
              status_msg: 'Error',
              added_msg: error.message,
              icon: 'mdi-information'
            })
          })

    }
  },

  mounted() {
      firestore()
          .collection("categories")
          .where("service", "==", "zhuNNxcggIqg8oEFeqH1")
          // .where("enabled", "==", true)
          .orderBy("index")
          .get().then(
        (response) => {
          this.productCategories = response.docs.map((d) => ({
            ...d.data(),
            id: d.id,
            text: d.data().name,
            value: d.id,
          }));
        }
    )
  }
}
</script>

<style scoped>

</style>