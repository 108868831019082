import {gql} from 'apollo-boost'

const fragments = `
{
    id
    price
    name
    img_url
    minimum
    weight
    units
    usage
    product_type{
        id
      name
      slug
    }
    company{
        id
      name
    }
     category{
        id
        name
        slug
    }
  }
`

export const search = gql`
    query products($search: String){
        products(
            where: {OR: [
                {name_contains: $search},
                {product_type: {name_contains: $search, tags_every: {name_contains: $search}}},
                {category: {name_contains: $search}},
                {company:{name_contains:$search}},
                {company:{location_contains:$search}},
            ]}
        )${fragments}
    }
`
