const BaseUrl = process.env.VUE_APP_API_BASE_URL || "http://localhost:4000/api";
let host = BaseUrl.substring(0, BaseUrl.length - 4);
const config = {
  locale: "en-US",
  apiBaseUrl: BaseUrl,
  host: host,
  guestID: "ck976o5vn003c0767a6xjcmtt", // Production
  // guestID: "cka3uz7up02eu0718d9f0qy2f", // Development
  imgHost: "https://akokomarket.imgix.net/",
  api: {
    customers: BaseUrl + "/customers"
  },
  tidio_key: "5eicmor9bgobuk8dowqxwzmg8dqyuvzw",
  firebase: {
    apiKey: "AIzaSyBCsXsO0viR5AyrKK2LxOnvtg59zC5Cq9Q",
    authDomain: "akokomarket-d5e1f.firebaseapp.com",
    databaseURL: "https://akokomarket-d5e1f.firebaseio.com",
    projectId: "akokomarket-d5e1f",
    storageBucket: "akokomarket-d5e1f.appspot.com",
    messagingSenderId: "325824432725",
    appId: "1:325824432725:web:d8da24448c8a0897b6a2dc",
    measurementId: "G-BJJ6F7W2JV"
  },


  // DEVELOPEMENT Server
  // graphqlUri:  "https://backup-akokomarket-b2d33f21da.herokuapp.com",
  // graphqlWsUri:  "wss://backup-akokomarket-b2d33f21da.herokuapp.com",

  //PRODUCTION SERVER
  graphqlUri: "https://main-akokomarket-12b844f56b.herokuapp.com/",
  graphqlWsUri: "wss://main-akokomarket-12b844f56b.herokuapp.com/graphql",
};

global.config = config;
export default config;
