var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"name","rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Name","required":""},model:{value:(_vm.formModel.name),callback:function ($$v) {_vm.$set(_vm.formModel, "name", $$v)},expression:"formModel.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"phoneNumber","rules":{
      required: true,
      max: 13,
      regex: '^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{4})(?: *x(\\d+))?\\s*$'
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Phone Number","required":""},model:{value:(_vm.formModel.phoneNumber),callback:function ($$v) {_vm.$set(_vm.formModel, "phoneNumber", $$v)},expression:"formModel.phoneNumber"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"product","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors,"label":"Product","items":_vm.productCategories,"item-text":"name","item-value":"id","return-object":"","required":""},on:{"change":_vm.getSelected},model:{value:(_vm.formModel.product),callback:function ($$v) {_vm.$set(_vm.formModel, "product", $$v)},expression:"formModel.product"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"price","rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Price","required":""},model:{value:(_vm.formModel.price),callback:function ($$v) {_vm.$set(_vm.formModel, "price", $$v)},expression:"formModel.price"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"quantity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Quantity","required":"","type":"number"},model:{value:(_vm.formModel.quantity),callback:function ($$v) {_vm.$set(_vm.formModel, "quantity", $$v)},expression:"formModel.quantity"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"region","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors,"label":"Region","items":_vm.getRegions,"item-text":"name","item-value":"id","required":""},model:{value:(_vm.formModel.region),callback:function ($$v) {_vm.$set(_vm.formModel, "region", $$v)},expression:"formModel.region"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"location","rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Location","required":""},model:{value:(_vm.formModel.location),callback:function ($$v) {_vm.$set(_vm.formModel, "location", $$v)},expression:"formModel.location"}})]}}],null,true)}),_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","disabled":invalid,"color":"primary","elevation":"0"}},[_vm._v(" submit ")]),_c('v-btn',{attrs:{"outlined":"","color":"error"},on:{"click":_vm.clear}},[_vm._v(" clear ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }