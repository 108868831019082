import {apolloClient} from  "../../main";
import * as prodQuery from '../../gql/product.gql';
import * as prodSearch from '../../../legacy/unwanted_gql/searchProducts.gql';
const products = {
  state: {
    products: [],
    found_product:{},
  },
  //Getters
  getters: {
    products: state => state.products,
    found_product: state => state.found_product,
  },

  //Mutations
  mutations: {
    setProducts(state, payload) {
      state.products = payload;
    },
    setFoundProduct(state, payload) {
      state.found_product = payload;
    },
  },

  //ACTIONS
  actions: {
    getProducts({state,commit},payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query:prodQuery.get,
        }).then(res=>{
          resolve(res);
        }).catch(err=>{
          console.log(err);
          reject(err);
        })
      })

    },
    searchProducts({state,commit},payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query:prodSearch.search,
          variables:{
            search:payload
          }
        }).then(res=>{
          resolve(res);
        }).catch(err=>{
          console.log(err);
          reject(err);
        })
      })

    },

  }
};

export default products;
