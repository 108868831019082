export default {
    getToken({ commit }, payload) {
        return this.firebase.auth().currentUser.getIdToken(true)
            .then((idToken)=>{
                return idToken
            }).catch(function(error) {
                throw error
            });
    },

    logoutUser({ commit }) {
        return new Promise((resolve, reject) => {
            this.firebase
                .auth()
                .signOut()
                .then(response => {
                    commit('setLoginState', false);
                    resolve(response);
                    localStorage.removeItem('token');
                })
                .catch(error => {
                    console.log(error);
                    reject(error.message);
                    commit('setFirebaseError', error.message);
                });
        })
    },
    removeItem({commit,state},payload){
        let index= state.activeCart.indexOf(payload);
        state.activeCart.splice(index,1);

    },

};
