import auth from "./auth";
import products from "./products";
import company from "./company";
import marketRequests from "./marketRequests";
import toolbar from './toolbar'
import notification from './notifications'
const modules = {
    auth,
    products,
    company,
    marketRequests,
    toolbar,
    notification
};
export default modules;
