import * as compQuery from '../../../legacy/unwanted_gql/company.gql';
import {apolloClient} from "../../main";

const company = {
    //State
    state: {
        companies: [],
        currentCompany: {}
    },

    //Getters
    getters: {
        getAllCompanies: state => state.companies,
        getCompanyById: state => id => state.companies(company => company.id.toString() === id.toString())
    },

    //Mutations
    mutations: {
        setAllCompanies(state, payload){
            state.companies = payload
        },
        setCurrentCompany(state, payload){
            state.currentCompany = payload
        }
    },

    //Actions
    actions: {
        allCompanies({state,commit}){
            return new Promise((resolve) => {
                apolloClient.query({
                    query: compQuery.get,
                }).then((res) => {
                    console.log(res)
                    resolve(res)
                }).catch((error) => {
                    console.error(error)
                })
            })
        }
    }
}

export default company
