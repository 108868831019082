import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi"
  },
  theme: {
    themes: {
      light: {
        primary: "#0B7B29",
        secondary: "#292929",
        tertiary: "#ffa21a",
        subsidiary: "#FDD835",
        accent: "#FFFFFF",
        error: "#f55a4e",
        info: "#00d3ee",
        success: "#5cb860",
        warning: "#ffa21a",
        greyish: "#eeeeee",
        back: "#F6FCF8"
      },
      dark: {
        primary: "#0B7B29",
        secondary: "#292929",
        tertiary: "#ffa21a",
        subsidiary: "#FDD835",
        accent: "#82B1FF",
        error: "#f55a4e",
        info: "#00d3ee",
        success: "#5cb860",
        warning: "#ffa21a",
        general: "#2196F3"
      }
    }
  }
});
