<template>
  <v-content>
      <v-col class="pa-0 mt-md-5 mt-9">
          <router-view transition="slide-x-transition" />
      </v-col>
  </v-content>
</template>

<script>
export default {
  name: "AView",
  metaInfo() {
    return {
      title: "Akokomarket"
    };
  }
};
</script>