<template>
    <v-list-item @click="markAsRead(notification)"
        :color="notification.status ? 'green': 'red'"
        :to="notification.to">
        <v-list-item-icon>
            <v-icon>{{notification.icon || 'mdi-bell-ring'}}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
            <!-- {{notification}} -->
            <v-list-item-title>{{notification.title}}</v-list-item-title>
            <v-list-item-subtitle>{{notification.message}}</v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
</template>
<script>
export default {
    props: {
        notification: {}
    },
    created(){
    },
    methods: {
        markAsRead(notification){
            console.log('notification clicked',notification)
            if(!notification.notQuery)
                this.$apollo.mutate({
                    mutation: require('@/gql/notifications.gql').update,
                    variables: {
                        where: {
                            id: notification.id
                        },
                        data: {
                            status: 1
                        }
                    }
                }).then(res=>{
                    console.log(res)
                    this.$store.commit('notification/updateNotification', res.data.updateNotification)
                })
        }
    }
}
</script>
