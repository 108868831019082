export default {
    path: '/business-dashboard',
    component: _=>import('../views/Agency'),
    meta: {
        showChat: false
    },
    children: [
        { path: 'auth', component: _ => import('../views/Agency/Auth'), children: [
                {name: 'agency-login', path: 'login', component: _=>import('../views/Agency/Auth/Login.vue')}
            ]
        },
        {
            path: '',
            component: _ => import('../views/Agency/Dashboard'),
            children:[
                { name: 'agency-dashboard',  path: '', component: _ => import('../views/Agency/Dashboard/Home') },
                { name: 'agency-manage-customers',  path: 'manage-customers', component: _ => import('../views/Agency/Dashboard/Customers') },
                { name: 'agency-manage-depot',  path: 'manage-depot', component: _ => import('../views/Agency/Dashboard/Depot') },
                { name: 'agency-manage-market-request',  path: 'manage-market-request', component: _ => import('../views/Agency/Dashboard/MarketRequest') },
                { name: 'agency-manage-products',  path: 'manage-products', component: _ => import('../views/Agency/Dashborad2/BusinessProducts.vue') },
                { name: 'agency-manage-transactions',  path: 'manage-transactions', component: _ => import('../views/Agency/Dashboard/Transactions.vue') },
                { name: 'agency-manage-sell-survey',  path: 'manage-sell-survey', component: _ => import('../views/Agency/Dashboard/SellSurvey.vue') },
                { name: 'agency-view-customer-products',  path: 'view-customer-products', component: _ => import('../components/datatable/CustomerProducts.vue'), props: true },
                { name: 'agency-settings',  path: 'settings', component: _ => import('../views/Agency/Dashborad2/Settings.vue')},
                { name: 'agency-manage-store-front',  path: 'manage-store-front', component: _ => import('../views/Agency/Dashborad2/StoreFront.vue')},
            ]
        }
    ]
}