export default {
    namespaced: true,
    state: {
        showToolbar: true
    },
    getters: {
        isShowToolbar: state => state.showToolbar
    },
    mutations: {
        showToolbar: (state, bool) => {
            state.showToolbar = bool
        }
    }
}