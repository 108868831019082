<template>
  <v-app>
      <v-row class="py-0 px-0 back">
          <v-snackbar :color="snackSet.color" :top="top" :left="left" :right="right"
                      v-model="snackbar" dark>
              <v-icon color="white" left>{{snackSet.icon ? snackSet.icon : 'mdi-bell-plus'}}</v-icon>
              <v-row>
                <v-col cols="10" class="d-block">
                  <b class="font-weight-bold subheading">{{snackSet.status_msg}}. </b>
                  <p>{{snackSet.added_msg}}</p>
                </v-col>
              <v-col cols="2">
                <v-icon right size="16" @click="snackbar = false">mdi-close-circle</v-icon>
              </v-col>
              </v-row>
          </v-snackbar>
          <a-view v-if="show_app"></a-view>
      </v-row>

    <v-overlay :value="!show_app" color="white" class=" text-center">
        <v-img class="animated slideInDown" src="./assets/imgs/akoko_market_logo.png"></v-img>
        <h3 class="font-weight-regular grey--text">Getting the market ready</h3>
        <v-progress-circular color="primary" class="mt-3" indeterminate size="32"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>
<script>
import Crud from './gql/PrismaCrud.gql'
import AHeader from "./components/Website/core/AHeader";
import AView from "./components/Website/core/AView";
import ASideBar from "./components/Website/core/ASideBar";
import Footer from "./components/Website/core/Footer";
import { mapGetters } from "vuex";
import {
  get as getNotifications,
  subscribe as notificationSubscribe
} from '@/gql/notifications.gql'

export default {
  name: "App",
  components: {
    AHeader,
    AView,
    ASideBar,
    Footer,
  },
  data: () => ({
     snackbar:false,
    top:true,
    left:false,
    bottom:false,
    right:false,
    show_app: false,
    dummyUser: {
      first_name: 'firstname',
      last_name: 'lastname',
      email: 'email',
      account_type: 'customer',
      enable_notification: 0,
    }
  }),
  computed: {
    ...mapGetters(["snackSet"]),
    ...mapGetters({ isShowToolbar : 'toolbar/isShowToolbar'})
  },
  mounted(){
    this.checkLoginState();
  },
  created(){
    this.firebase.auth().onAuthStateChanged(user=>{
      // There should be a unversal loader for when this function inits
      if(user){
        //user exists. push to manage products
        // this.$router.push({name: 'agency-dashboard'})
        // this.$router.push({name: 'admin-dashboard'})
        this.firebase.analytics().logEvent('user_account', {
            type: 'user',
            id: user.uid
        });
        user.getIdToken().then(token=>{
          this.$store.commit('auth/setUserToken', token)
          this.$apollo.query({
            query: require('@/gql/user.gql').one,
            variables: {
              where: { firebase_id : user.uid }
            }
          }).then(res=>{
            user = res.data.user;
            console.log("Check for user on our local.")
            console.log({DBUser: user})
            if(user){ // if user exists on our DB
              this.setUpNotifications(user);
              this.$store.commit('auth/setUserData', user);
                  if(user.company){
                    console.log({route: user.company})
                      if(this.$route.matched[0].path !== '/admin' && this.$route.name == 'home')
                        this.$router.push({name:'agency-manage-products'})
                  }else{
                    this.$router.push({name:'agency-manage-products'})

                    // this.$router.push({name:'sell-with-us'});
                      console.log({nocompany: user.company})
                  }
              }else{ // if user does not exist in our database

                  console.log('User is not in our Database.')
              this.saveDetails()
            }
            this.show_app = true
          })
        })
      }
      else{
        console.log("user Does not exist on firebase.")
        this.$store.commit('auth/setUserToken', null)
        this.$store.commit('auth/setUserData', null)
        this.show_app = true
      }
    })
  },
  watch: {
    snackSet() {
      this.snackbar = true;
    }
  },
  methods:{
    saveDetails() {
      let firebaseID = "";
      this.firebase.auth().onAuthStateChanged(user=>{
        if(user) {
          // alert('user exists on firebase.')
          console.log({fireuserDAta: user})

          firebaseID = user.uid;
          this.$store.commit('setLoading',true);
          this.$apollo.mutate({
            mutation: require('@/gql/user.gql').create,
            variables: {
              data: {...this.dummyUser, phone: user.phoneNumber,firebase_id : user.uid }
            }
          }).then(res=>{
            // store new user to vuex and push to previous page
            this.$store.commit('setSnack', {
              color: 'green',
              status_msg: 'All done',
              icon: 'mdi-check-circle'
            })
            this.$store.commit('auth/setUserData', res.data.createUser);
            const userId = res.data.createUser.id;
            // this.AddCompanyToUser(userId)


          }).catch(err=>{
            console.error(err.message)
            this.$store.commit('setSnack', {
              color: 'red',
              status_msg: 'Error proccess your credentials',
              added_msg: 'This may because email or phone already exists',
              icon: 'mdi-information'
            })
          }).finally(_=>{
            this.$store.commit('setLoading',false);
          })





        }else{
          return false
        }
      })


    },
    checkLoginState(){
      const token = localStorage.getItem('token');
      if(token){
        this.$store.commit('setSnack', { color: 'green', status_msg: 'Welcome.', added_msg: 'You are logged in.' })
      }
    },

    async setUpNotifications(user){
      await this.$apollo.query({
        query: getNotifications,
        variables: {
          where: {
            users_every: { id: user.id },
            status: 0
          }
        }
      }).then(res=>{
          this.$store.commit('notification/setNotifications', res.data.notifications)
      }).catch(err=>{
          console.log(err)
      })

      const notificationObserver = this.$apollo.subscribe({
        query: notificationSubscribe,
        variables:{
          where: {
            users_every: { id: user.id },
          }
        }
      })

      notificationObserver.subscribe({
        next: (res)=>{
          switch(res.data.notification.mutation){
            case 'UPDATED':
              this.$store.commit('notification/updatedNotification', res.data.notification.node)
              break
            case 'CREATED':
              this.$store.commit('notification/addNotification', res.data.notification.node)
              break
          }
        },
        error: (error)=>{
          console.error(error)
        },
      })
    },

    authenticateUser(user, token){
      return this.$store.dispatch('auth/setAuthentication', {
        user,
        token
      })
    },


    async fetchUser(where){
      console.log('where',{where})
      return this.$apollo.query({
            query: one,
            variables: {
              where
            }
          }).then(res=>{
            return res.data.user
          }).catch(err=>{
            console.error('error fetching user', err)
          })
    },
  }
};
</script>

<style lang="scss">
#app {
  font-family: Poppins, sans-serif; /* this was it */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.v-list-item--active {
  color: white !important;
  font-weight: bold;
}

html, body {
  margin: 0px !important;
  padding: 0px !important;
  overflow-x: hidden;
}

</style>
