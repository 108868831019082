var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":"","nudge-bottom":"20"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","text":"","color":"primary"}},on),[_c('v-badge',{attrs:{"color":"red","content":_vm.unreadNotifications.length,"value":_vm.unreadNotifications.length,"overlap":""}},[_c('v-icon',[_vm._v("mdi-bell")])],1)],1)]}}])},[_c('notifications',{attrs:{"minimize":true}},[_c('template',{slot:"all-notifications"},[_c('pallete',{attrs:{"notification":{
                    notQuery: true,
                    title: 'View all notifications',
                    icon: 'mdi-clock',
                    to: _vm.allNotificationsLink,
                    color: 'primary--text'
                }}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }