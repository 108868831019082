// https://vuex.vuejs.org/en/getters.html

export default {
  getApi: state => state.config.api,
  guestID: state => state.config.guestID,
  getImgHost: state => state.config.imgHost,
  snackSet: state => state.snackSet,
  loginState:state =>state.loginState,
  showLogin: state => state.showLogin,
  showPhoneCode: state => state.showPhoneCode,
  loading: state => state.loading,
  getCategories: state => state.categories,
  getPhone: state => state.phone,
  cartMenu: state => state.cart_menu,
  getCart: state => state.activeCart,
  getCompanyDialog: state => state.companyDialog,
  getCreateItemDialog: state => state.createItemDialog,
};
