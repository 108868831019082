import {gql} from 'apollo-boost'

const fragments = `
{
    id
    name
    owner
    phone
    depots{
        id
        name
        phone
        location
    }
    storefront{
        id
    }
    createdAt
}
`

export const create = gql`
    mutation createCompany($data: CompanyCreateInput!){
        createCompany(data: $data)${fragments}
    }
`

export const get = gql`
    query companies($where: CompanyWhereInput!){
        companies(where: $where)${fragments}
    }
`

export const update = gql`
    mutation updateCompany($where: CompanyWhereUniqueInput!, $data: CompanyUpdateInput!){
        updateCompany(where: $where, data: $data)${fragments}
    }
`

export const remove = gql`
    mutation deleteCompany($where: CompanyWhereUniqueInput!){
        deleteCompany(where: $where)${fragments}
    }
`
