export default {
    namespaced: true,
    state: {
        notifications: []
    },
    getters: {
        getNotifications(state){
            return state.notifications
        }
    },
    mutations: {
        deleteAllNotification(state){
            state.notifications = []
        },
        setNotifications(state, notifications){
            notifications.forEach(notification=>{
                state.notifications.push(notification)
            })
        },
        updatedNotification(state, notification){
            state.notifications = state.notifications.map(oldNote=>{
                if(oldNote.id !== notification.id) {
                    return oldNote
                }else{
                    return notification
                }
            })
        },
        addNotification(state, notification){
            state.notifications.unshift(notification)
        },
        updateNotification(state, notification){
            state.notifications = state.notifications.map(oldNote=>{
                if(oldNote.id == notification.id)
                    return notification
                return oldNote
            })
        }
    }
}