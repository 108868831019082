import store from "@/store";

const user = store.getters['auth/getUserData']

export default {
    path: '/admin',
    component: _ => import('../views/Admin'),
    children: [
        {
            path: 'auth',
            component: _ => import('../views/Admin/Auth'),
            children: [
                {
                    name: 'admin-login',
                    path: 'login',
                    component: _ => import('../views/Admin/Auth/Login.vue')
                }
            ]
        },
        {
            path: '',
            component: _ => import('../views/Admin/Dashboard'),
            children: [
                {
                    name: 'admin-dashboard', path: '',
                    component: _ => import('../views/Admin/Dashboard/Home')
                },
                {
                    name: 'admin-manage-logistics',
                    path: 'manage-logistics',
                    component: _ => import('../views/Admin/Dashboard2/Logistics'),
                    props: true
                },
                {
                    name: 'admin-manage-users',
                    path: 'manage-users',
                    component: _ => import('../views/Admin/Dashboard2/Users'),
                    props: true
                },
                {
                    name: 'admin-manage-depot',
                    path: 'manage-depot',
                    component: _ => import('../views/Admin/Dashboard/Depot'),
                    props: true
                },
                {
                    name: 'admin-manage-company',
                    path: 'manage-company',
                    component: _ => import('../views/Admin/Dashboard/Company'),
                    props: true
                },
                {
                    name: 'admin-review-products',
                    path: 'review-products',
                    component: _ => import('../views/Admin/Dashboard2/Products.vue')
                },
                {
                    name: 'admin-products',
                    path: 'products',
                    component: _ => import('../views/Agency/Dashboard/Products.vue')
                },
                {
                    name: 'admin-manage-products',
                    path: 'manage-products',
                    component: _ => import('../views/Admin/Dashboard/ProductTypes'),
                    props: true
                },
                {
                    name: 'admin-manage-categories',
                    path: 'manage-categories',
                    component: _ => import('../views/Admin/Dashboard/ProductCategory.vue'),
                    props: true
                },
                {
                    name: 'admin-manage-company-products',
                    path: 'company-products',
                    component: _ => import('../views/Admin/Dashboard/CompanyProducts'),
                    props: true
                },
                {
                    name: 'admin-product-category',
                    path: 'manage-products-category',
                    component: _ => import('../views/Admin/Dashboard/ProductCategory.vue'),
                    props: true
                },
                {
                    name: 'admin-manage-market-request',
                    path: 'manage-market-request',
                    component: _ => import('../views/Admin/Dashboard/MarketRequest')
                },
                {
                    name: 'admin-notifications',
                    path: 'notifications',
                    component: _ => import('../views/Admin/Dashboard/Notifications')
                },
                {
                    name: 'admin-view-customer-products',
                    path: 'view-customer-products',
                    component: _ => import('../components/datatable/CustomerProducts.vue'),
                    props: true
                },
            ]
        }
    ]
}
