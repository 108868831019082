<template>
    <v-menu offset-y nudge-bottom="20">
        <template v-slot:activator="{on}">
            <v-btn
                icon
                text
                color="primary"
                v-on="on"
            >
                <v-badge
                    color="red"
                    :content="unreadNotifications.length"
                    :value="unreadNotifications.length"
                    overlap
                >
                    <v-icon>mdi-bell</v-icon>
                </v-badge>
            </v-btn>
        </template>
        <notifications :minimize="true">
            <template slot="all-notifications">
                <pallete
                    :notification="{
                        notQuery: true,
                        title: 'View all notifications',
                        icon: 'mdi-clock',
                        to: allNotificationsLink,
                        color: 'primary--text'
                    }"
                />
            </template>
        </notifications>
    </v-menu>
</template>
<script>
import notifications from './notificationView'
import pallete from './notificationPallete'
import {mapGetters} from 'vuex'
export default {
    components: {pallete, notifications},
    props: ['allNotificationsLink'],
    computed: {
        ...mapGetters({notifications : 'notification/getNotifications'}),
        unreadNotifications(){
            return this.notifications.filter(notification=>{
                return notification.status === 0
            })
        }
    },
}
</script>
