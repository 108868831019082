<template>
  <v-footer>
    <v-row
      justify="center"
      id="core-footer"
      class="mb-n10"
  >
    <v-col cols="12" class="footer">
      <v-row
        justify="space-around"
        class="text-center mx-3"
      >
        <div class="col-md-3 footer-grid ">
          <h3>About Us </h3>
          <ul>
            <li><a href="/about_akokomarket" target="_blank">About AkokoMarket</a></li>
            <li><a href="/terms_&_conditions" target="_blank">Terms & Conditions</a></li>
            <li><a href="/privacy_policy" target="_blank">Privacy Policy</a></li>
          </ul>
        </div>

        <div class="col-md-3 footer-grid">
          <h3>Partners</h3>
          <ul>
            <li><a href="https://www.acdivoca.org/projects/ghana-poultry-project-gpp/" target="_blank">Ghana Poultry Project</a></li>
            <li><a href="https://gpp.com" target="_blank">GNAPF</a></li>
            <li><a href="https://www.cta.int/en/channel/pitch-agrihack-sid04a44b898-038e-40e1-a516-6c76b77fbd55" target="_blank">CTA Pitch Agrihack</a></li>
            <li><a href="https://meltwater.org" target="_blank">Meltwater Ghana</a></li>
          </ul>
        </div>

        <div class="col-md-3 footer-grid ">
          <h3>Support</h3>
          <ul>
            <!-- <li><a href="#" target="_blank">FAQ's</a></li> -->
            <li><a href="/contact_us" target="_blank">Contact Us</a></li>
          </ul>
        </div>
        <div class="col-md-3 footer-grid">
          <h3>Subscribe With Us </h3>
          <v-form>
            <v-container>
              <v-row>
                  <v-col cols="12" >
                  <v-text-field
                          label="Please enter your email ..."
                          solo
                          dense
                          clearable
                          append-icon="mdi-magnify"
                          v-model="subscription"
                  >
                    <template v-slot:append>
                      <v-fade-transition leave-absolute>
                        <v-progress-circular
                                v-if="loading"
                                size="24"
                                color="success"
                                indeterminate
                        ></v-progress-circular>
                        <v-btn icon large v-else color="green darken-2" @click="goToSubscription">
                          <v-icon>mdi-send</v-icon>
                          </v-btn>
                      </v-fade-transition>
                    </template>
                  </v-text-field>
                </v-col>

              </v-row>

              <v-dialog v-model="subscriptionDialog">
                <v-card class="">
                    <v-row class="mx-2" dense>
                      <v-col cols="4" sm="4"> 
                        <v-img src="@/assets/imgs/thanks.jpg" width="250" cover/>
                      </v-col>
                      <v-col cols="8" sm="8" align-self="center">
                        <v-btn style="position: absolute; right: 0; top:0;" icon @click="subscriptionDialog=false" class="my-2">
                            <v-icon class="red--text lighten-1">mdi-close</v-icon>
                        </v-btn>
                        <h4 class="subs primary--text">You have successfully <br>subscribed to AkokoMarket</h4>
                      </v-col>
                    </v-row>
                </v-card>
            </v-dialog>
            </v-container>
          </v-form>
        </div>
      </v-row>
      <!--      Center Piece with Akokomarket Logo-->
        <div class="footer-bottom mt-12" style="text-align:center">
          <h2 ><a href="/">
            <v-img :src="require('../../../assets/imgs/akokomarket-web.png')" width="300"></v-img>
            <span>CONNECTING FARMERS TO MARKET</span></a></h2>
          <p class="fo-para">
            All your fresh farm products needs in one place. Buy and Sell with us for a rich experience.
            We have vetted and verified each one of our products for your safety.</p>
          <ul class="social-fo">
            <li><a href="https://web.facebook.com/akokomarket" target="_blank" class="face"><i class="fab fa-facebook" aria-hidden="true"></i></a></li>
            <li><a href="https://www.linkedin.com/company/akoko-market/" target="_blank" class=" twi"><i class="fab fa-twitter" aria-hidden="true"></i></a></li>
            <li><a href="https://twitter.com/AkokoMarket" target="_blank" class=""><i class="fab fa-linkedin" aria-hidden="true"></i></a></li>
          </ul>
        <v-row>
            <v-col
                v-for="n in footy"
                :key="n"
                cols="6"
                md="4"
            >
              <v-card
                      class="pa-2 transparent white--text"
                      flat
              >
              <p style="font-size: small;">
                {{n}}
              </p>
              </v-card>
            </v-col>
          </v-row>
      <div class="copy-right">
        <p>&copy; 2023 Akokomarket All Rights Reserved | Design by  <a href="http://app.akokomarket.com/"> Agro Innova</a>. V.{{version}}</p>
      </div>
    </div>
    </v-col>
  </v-row>
</v-footer>
</template>

<script>
  import pkg from '../../../../package.json'
  import {firestore} from "firebase";

  export default {
    data: () => ({
      subscription: "",
      subscriptionDialog: false,
      links: [
        { name: 'Home', Link: '/dashboard' },
      ],
      loading:false,
      footy:["info@akokomarket.com","+233 30 255 7422 / +233 240 956 276","No. 31 Ogbojo Koo St, Accra"],
      year: new Date().getFullYear(),
      version: pkg.version
    }),
    methods:{
      goToSubscription(){
        if (!this.subscription){
          alert("Please enter your email address")
          return
        } else {
          const subObject = {
            email: this.subscription,
            date: new Date()
          }
          console.log(subObject, "------printing subscription")

          let batch = firestore().batch();
          firestore().collection("subscriptions").doc().set({
            subObject
          })
          this.subscriptionDialog = true;
        }
      }
    }
  }
</script>

<style scoped>
  /*#core-footer {*/
  /*  z-index: 45;*/
  /*  background-color:#eeeeee;*/
  /*}*/
  @import '../../../assets/commerce_styles.css';

  @media screen and (max-width: 600px) {
    .subs {
      font-size: small !important;
    }
  }
</style>
