import axios from 'axios'
import config from '../config'

axios.defaults.baseURL = config.apiBaseUrl;
axios.defaults.headers.get["Accept"] = "application/json";
const token = localStorage.getItem("token");
if (token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  axios.defaults.headers.common["id_token"] = token;
}

export default {
    install: (Vue, options)=>{
        Vue.prototype.$http = axios
    }
}