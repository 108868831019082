var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isMobile)?_c('v-app-bar',{attrs:{"color":"white","dense":"","fixed":"","app":""}},[_c('v-toolbar-title',{staticClass:"ml-n2",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({name: 'home'})}}},[_c('v-img',{attrs:{"src":require("@/assets/imgs/akoko_market_logo.png"),"lazy-src":require("@/assets/imgs/akoko_market_logo.png")}})],1),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":true,"offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-badge',{attrs:{"right":"","overlap":"","color":"red"}},[_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_vm._v(_vm._s(_vm.noItems))]),_c('v-icon',_vm._g({attrs:{"color":"primary","left":""}},Object.assign({}, tooltip, menu)),[_vm._v("mdi-cart")])],1)]}}],null,true)},[_c('span',[_vm._v("View cart items")])])]}}],null,false,365477263)},[_c('user-cart')],1),_c('NotificationButton',{attrs:{"allNotificationsLink":{name: 'notifications'}}}),_c('v-menu',{attrs:{"bottom":"","right":"","offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary","rounded":""}},on),[_c('v-icon',[_vm._v("mdi-account-circle")])],1)]}}],null,false,431843083)},[_c('v-card',[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.listo),function(item,i){return _c('v-list-item',{key:i,attrs:{"dense":""},on:{"click":item.click}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":item.color}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1),(_vm.userData)?_c('v-btn',{staticClass:"elevation-4",attrs:{"small":"","color":"primary","rounded":""},on:{"click":function($event){return _vm.$router.push({name: 'agency-dashboard'})}}},[_vm._v(" DASHBOARD ")]):_c('v-btn',{staticClass:"primary mr-4",attrs:{"dense":"","dark":"","rounded":""},on:{"click":function($event){return _vm.$router.push({name: 'sell-with-us'})}}},[_vm._v(" Sell ")])],1):_c('v-app-bar',{staticClass:"elevation-0 ma-3",staticStyle:{"border-radius":"72px"},attrs:{"app":"","dense":"","height":"72"}},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-toolbar-title',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({name: 'home'})}}},[_c('v-img',{attrs:{"src":require("@/assets/imgs/akoko_market_logo.png"),"lazy-src":require("@/assets/imgs/akoko_market_logo.png")}})],1),_c('v-toolbar-items',[_c('v-row',{staticClass:"mx-0",attrs:{"align":"center"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","dark":"","text":"","rounded":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-information")]),_vm._v(" Help ")],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-phone")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Contact Us")]),_c('v-list-item-title',{staticClass:"caption"},[_vm._v("0553322008 / 0508538802")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{staticClass:"grey--text text--darken1",on:{"click":_vm.getToken}},[_vm._v("mdi-registered-trademark")])],1)],1)],1)],1),_c('v-divider')],1)],1),_c('v-menu',{attrs:{"close-on-content-click":true,"offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","text":"","rounded":"","dark":""}},Object.assign({}, tooltip, menu)),[_c('v-badge',{attrs:{"left":"","color":"red darken-2"}},[_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_vm._v(_vm._s(_vm.noItems))]),_c('v-icon',{attrs:{"left":"","color":" "}},[_vm._v(" shopping_cart ")])],1),_vm._v(" CART ")],1)]}}],null,true)},[_c('span',[_vm._v("View cart items")])])]}}])},[_c('user-cart')],1),_c('NotificationButton'),_c('v-menu',{attrs:{"bottom":"","right":"","offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"color":"primary","icon":"","rounded":""}},on),[_c('v-icon',[_vm._v("mdi-account-circle")])],1)]}}])},[_c('v-card',[_c('v-list',_vm._l((_vm.listo),function(item,i){return _c('v-list-item',{key:i,on:{"click":item.click}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":item.color}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1),(_vm.userData)?_c('v-btn',{staticClass:"primary mr-4",attrs:{"dark":"","rounded":""},on:{"click":function($event){return _vm.$router.push({name: 'agency-dashboard'})}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-home")]),_vm._v(" Dashboard ")],1):_c('v-btn',{staticClass:"primary mr-4",attrs:{"dark":"","rounded":""},on:{"click":function($event){return _vm.$router.push({name: 'sell-with-us'})}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cart")]),_vm._v(" Sell with us ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }