<template>
    <v-list shaped :dense="minimize" style="min-width: 320px">
        <v-subheader><v-icon left>mdi-clock-outline</v-icon> Notifications</v-subheader>
        <v-list-item-group color="primary">
            <pallete v-for="(notification, index) in unreadNotifications" 
                :key="index" 
                :notification="notification"
                v-if="showNotification(notification, index)"
            />
            <v-list-item v-if="!notifications">
                <v-list-item-title class="grey--text">
                    No notifications
                </v-list-item-title>
            </v-list-item>  
            <slot name="all-notifications"></slot>
        </v-list-item-group>
    </v-list>
</template>
<script>
import Pallete from './notificationPallete'
import {mapGetters} from 'vuex'
export default {
    components: {Pallete},
    props:{
        minimize: {
            type: Boolean,
            default: ()=>false
        }
    },
    computed: {
        ...mapGetters({notifications: 'notification/getNotifications'}),
        unreadNotifications(){
            return this.notifications.filter(notification=>{
                return notification.status === 0
            })
        }
    },
    methods:{
        showNotification(notification, index){
            return !this.minimize ? true : index < 4 
        }
    },
}
</script>
