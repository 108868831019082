<template>
    <v-col cols="12" sm="6" md="4">
        <v-text-field
                filled
                placeholder="Type product name here..."
                outlined
                rounded
                dense
                append-icon="mdi-magnify"
        >
            <template v-slot:append>
                <v-fade-transition leave-absolute>
                    <v-progress-circular
                            v-if="loading"
                            size="24"
                            color="primary"
                            indeterminate
                    ></v-progress-circular>
                    <v-icon @click="loading = !loading" v-else>
                        mdi-magnify
                    </v-icon>
                </v-fade-transition>
            </template>
        </v-text-field>
    </v-col>
</template>

<script>
    export default {
        name: "CustomSearch",
        data(){
            return{
                loading:false,
            }
        }
    }
</script>

<style scoped>

</style>
