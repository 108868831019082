import {gql} from 'apollo-boost'

const fragment = `{
    title
    message
    id
    action
    type
    status
    role
    createdAt
}
`

export const create = gql`
    mutation createNotification($data: NotificationCreateInput!){
        createNotification(data: $data)${fragment}
}
`

export const get = gql`
    query notifications($where: NotificationWhereInput){
    notifications(where: $where)${fragment}
}
`

export const update = gql`
    mutation updateNotification($where: NotificationWhereUniqueInput! $data: NotificationUpdateInput!){
        updateNotification(where: $where, data: $data)${fragment}
    }
`

export const remove = gql`
    mutation deleteNotification($where: NotificationWhereUniqueInput!){
        deleteNotification(where: $where){
            id
        }
    }
`

export const subscribe = gql`
    subscription notification($where: NotificationWhereInput){
        notification(where: {
            node: $where
        }){
            mutation
            node{
            id
            title
            status
            }
        }
    }
`   