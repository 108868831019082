<template>
  <div>
    <!--    for mobile-->
    <v-app-bar
            v-if="isMobile"
            color="white"
            dense
            fixed
            app 
            class="px-0"
            style="top: 25px"
      >
        <v-toolbar-title
                style="cursor:pointer"
                @click="$router.push({name: 'home'})"
        >
          <v-img width="150" src="@/assets/imgs/akoko_market_logo.png"
                  lazy-src="@/assets/imgs/akoko_market_logo.png"/>
        </v-toolbar-title>
        <v-spacer></v-spacer>


<!--      Search icon-->
      <v-menu
              v-model="menu"
              :close-on-content-click="true"
              :nudge-width="200"
              offset-y
              transition="scale-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn
                  color="primary"
                  dark
                  icon
                  v-on="on"
                  text
                  class="mr-2"
          >
            <v-icon left>mdi-phone</v-icon>
<!--            Help-->
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-phone</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-subtitle>Contact Us</v-list-item-subtitle>
                <v-divider/>
                <v-list-item-title class="caption" ><a href="tel://0240956276">0240956276 </a></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
        </v-card>
      </v-menu>



      <v-dialog
              v-model="cartMenu"
              :close-on-content-click="true"
              offset-y
              transition="scale-transition"
              :fullscreen="isSmallScreen"
      >
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-badge
                      right
                      overlap
                      color="red"
                      class="mr-4"
              >
                <span slot="badge">{{ noItems }}</span>
                <v-icon
                        color="primary"
                        v-on="{ ...tooltip, ...menu }"
                        left>mdi-cart</v-icon>

              </v-badge>

            </template>
            <span>View cart items</span>
          </v-tooltip>
        </template>
        <user-cart @hideDialog="cartMenu = false"></user-cart>
      </v-dialog>
      <v-dialog
          v-model="sellMenu"
          width="80%"
      >
        <v-card
            class="pa-3"
        >
          <v-btn
              icon
              style="position: absolute; top: 2px; right: 4px"
              @click="sellMenu = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <h3>Partner Form</h3>
          <sell-form @close-dialog="sellMenu = false" />
        </v-card>
      </v-dialog>
<!--      <v-menu-->
<!--              :close-on-content-click="false"-->
<!--              offset-y-->
<!--              :nudge-width="320"-->
<!--              transition="scale-transition"-->
<!--      >-->
<!--        <template v-slot:activator="{ on: menu }">-->
<!--          <v-tooltip bottom>-->
<!--            <template v-slot:activator="{ on: tooltip }">-->
<!--              <v-btn-->
<!--                      style="text-transform:unset"-->
<!--                      class="ml-6"-->
<!--                      color="primary"-->
<!--                      v-on="{ ...tooltip, ...menu }"-->
<!--                      small-->
<!--                      outlined-->
<!--                      rounded-->
<!--                      dark>-->
<!--                &lt;!&ndash; <v-icon small left>mdi-lock-open</v-icon> &ndash;&gt;-->
<!--                Sell Here-->
<!--              </v-btn>-->
<!--            </template>-->
<!--            <span>Login</span>-->
<!--          </v-tooltip>-->
<!--        </template>-->
<!--        <sign-user></sign-user>-->
<!--      </v-menu>-->
      <v-btn
          color="primary"
          class="px-2 font-weight-bold"
          small
          dark
          @click="goToSellMenu"
      >
        <!-- <v-icon left>mdi-cart</v-icon> -->
        Join us

      </v-btn>
      <div class="top-bar" style="height: 25px; padding-top: 3px">
        <div class="moving_text_small">
          Farm Gate Price &nbsp&nbsp •
          &nbsp&nbsp Quality Assured
          &nbsp&nbsp •
          &nbsp&nbsp  Timely Delivery
          &nbsp&nbsp •
          &nbsp&nbsp Comfort
          &nbsp&nbsp •
          &nbsp&nbsp
          Fresh Products
        </div>
      </div>
    </v-app-bar>
<!--    For large Screen-->
    <v-app-bar
        v-else
        app
        dense
        height="80"
        class="elevation-0 px-0"
        style="background:rgba(255,255,255,0.9); top: 25px"
    >
      <v-row justify="space-between" align="center">
        <v-toolbar-title class="pl-5" @click="$router.push({name: 'home'})" style="cursor:pointer" >
          <v-img width="210px" src="@/assets/imgs/akoko_market_logo.png"
                 lazy-src="@/assets/imgs/akoko_market_logo.png"/>
        </v-toolbar-title>
        <!--          <custom-search class="mt-6"></custom-search>-->
        <!--          <auto-search class="mt-0"/>-->
        <v-toolbar-items>
          <v-row class="mx-0">
            <v-menu
                v-model="menu"
                :close-on-content-click="true"
                :nudge-width="200"
                offset-y
                transition="scale-transition"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                    color="black"
                    dark
                    v-on="on"
                    text
                    class="mr-4"
                >
                  <v-icon left>mdi-information</v-icon>
                  Help
                </v-btn>
              </template>

              <v-card>
                <v-list>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon>mdi-phone</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-subtitle>Contact Us</v-list-item-subtitle>
                      <v-list-item-title>
                        <a href="tel://0240956276">0240956276</a>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <v-divider></v-divider>


              </v-card>
            </v-menu>
            <!--CART DROPDOWN-->
            <v-dialog
                v-model="cartMenu"
                :close-on-content-click="true"
                offset-y
                transition="scale-transition"
            >
              <template v-slot:activator="{ on: menu }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                        color="primary"
                        v-on="{ ...tooltip, ...menu }"
                        text
                        class="mr-4"
                        dark>
                      <v-icon left>mdi-cart</v-icon>

                      <v-badge
                          right
                          color="red"
                      >
                        <span slot="badge">{{ noItems }}</span>
                        CART
                      </v-badge>

                    </v-btn>

                  </template>
                  <span>View cart items</span>
                </v-tooltip>
              </template>
              <user-cart @hideDialog="cartMenu = false"></user-cart>

            </v-dialog>
            <v-dialog
                v-model="sellMenu"
                width="800"
            >
              <v-card
                  class="pa-5"
              >
                <v-btn
                    icon
                    style="position: absolute; top: 5px; right: 7px"
                    @click="sellMenu = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <h2>Partner Form</h2>
                <sell-form @close-dialog="sellMenu = false" />
              </v-card>
            </v-dialog>
            <!--LOGIN DROPDOWN MENU-->
            <!--              <v-menu-->
            <!--                      :close-on-content-click="false"-->
            <!--                      offset-y-->
            <!--              >-->
            <!--                <template v-slot:activator="{ on: menu }">-->
            <!--                      <v-btn  v-on="{...menu }"-->
            <!--                        color="primary"-->
            <!--                        class="mr-4 px-12 font-weight-bold"-->
            <!--                        dark>-->
            <!--                          &lt;!&ndash; <v-icon left>mdi-cart</v-icon> &ndash;&gt;-->
            <!--                          Sell Here-->

            <!--                      </v-btn>-->
            <!--                </template>-->
            <!--                <sign-user></sign-user>-->
            <!--              </v-menu>-->
            <div class="d-flex justify-center mr-5" style="width: 200px">
              <v-btn
                  color="primary"
                  class="join_btn mr-4 px-12 font-weight-bold"
                  dark
                  @click="goToSellMenu"
              >
                <!-- <v-icon left>mdi-cart</v-icon> -->
                Join Us As Farmer

              </v-btn>
            </div>

            <!--              cart drop and login drop-->


          </v-row>
        </v-toolbar-items>
      </v-row>
      <div class="top-bar" style="height: 30px">
        <div class="moving_text">
          Farm Gate Price &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp •
          &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp Quality Assured
          &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp •
          &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp  Timely Delivery
          &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp •
          &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp Comfort
          &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp •
          &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp
          Fresh Products
        </div>
      </div>
    </v-app-bar>

  </div>
</template>

<script>
// Utilities
import { mapMutations, mapGetters } from "vuex";
import CustomSearch from "@/components/Website/CustomSearch";
import AutoSearch from "@/components/Website/AutoSearch";
import SignUser from "@/components/Website/Customer/SignUser";
import UserCart from "@/components/Website/Customer/UserCart";
import SellForm from "@/components/Website/Customer/SellForm.vue";

import mixpanel from "mixpanel-browser";

mixpanel.init("6a7537bf8311e4fc76404e3362906a58", { debug: true, track_pageview: true });

export default {
  name: "OpenHeader",
  computed: {
    noItems(){
      return this.$store.getters.getCart.length;
    },
    ...mapGetters(['loginState']),
    breakPoint(){
     return this.$vuetify.breakpoint
    },
  },
  data() {
    return {
      cartMenu: false,
      menu:false,
      sellMenu: false,
      searchMenu:false,
      websiteToolbar: true,
      adminToolbar: false,
      formProductCategories: [],
      trending_com:[
        {
          id:1,
          company:"Wilmar Farms",
          status:"Online",
          product:"Fresh Eggs, Broilers, Layers",
          location:"Adabraka",
          avatar: 'rooster.png'
        },
        {
          id:1,
          company:"Agro Innova Farms",
          status:"Online",
          product:"Fresh Eggs, Broilers, Layers",
          location:"Adabraka",
          avatar: 'rooster.png'
        },{
          id:1,
          company:"Boris B. Farms",
          status:"Online",
          product:"Fresh Eggs, Broilers, Layers",
          location:"Adabraka",
          avatar: 'rooster.png'
        },
      ],
      items: [
        { text: "Fresh Eggs", icon: " mdi-circle-small" },
        { text: "Local Poultry", icon: " mdi-circle-small" },
        { text: "Yellow Maize", icon: " mdi-circle-small" },
        { text: "Poultry Drugs", icon: " mdi-circle-small" },
        { text: "Poultry Equipment", icon: " mdi-circle-small" },
        { text: "Day Old Chicks", icon: " mdi-circle-small" },
        { text: "Poultry Feed", icon: " mdi-circle-small" },
        { text: "Feed Mill", icon: " mdi-circle-small" },
        { text: "Veterinary Services", icon: " mdi-circle-small" },
        { text: "Other Livestock", icon: " mdi-circle-small" },
      ],
    };
  },

  watch: {
    $route(val) {
      this.title = val.name;
    },
  },
  mounted() {
      // console.log(this.$vuetify.breakpoint)
  },
  beforeDestroy() {},

  methods: {
    goToSellMenu(){
      this.sellMenu = true
      mixpanel.track("Join us farmer Clicks")
    },
    clearLoader(){
      this.$store.commit("setLoading",false);
    },
    getToken(){
      localStorage.removeItem('token');
      this.$store.dispatch('getToken')
              .then((response) =>{
                localStorage.setItem('token', response)
                alert(response);
                console.log(response);
              })
              .catch(err => {
                self.loader = false;
                alert("user not logged in.")
                console.log(err)
              })
    },

  },
  components:{
    CustomSearch,
    AutoSearch,
    SignUser,
    UserCart,
    SellForm
  }
};

</script>

<style lang="scss">
#core-app-bar {
  width: auto;
}

.moving_text{
  animation: moveText 22s linear infinite;
  white-space: nowrap;
  color: #FFFFFF;
  font-size: large;
  letter-spacing: 2px;
  font-weight: bold;
}

.moving_text_small{
  animation: moveText 15s linear infinite;
  white-space: nowrap;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: normal;
}

.join_btn {
  animation-name: beep;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@keyframes beep {
  0% {width: 200px}
  10% {width: 198px}
  20% {width: 196px}
  30% {width: 194px}
  40% {width: 192px}
  50% {width: 190px}
  60% {width: 188px}
  70% {width: 186px}
  80% {width: 184px}
  90% {width: 182px}
  100% {width: 180px}
}

@keyframes moveText {
  0% {
    transform: translateX(100%);
  }
  25% {
    transform: translateX(50%);
  }
  50% {
    transform: translateX(0%);
  }
  75% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(-100%);
  }
}
/* .v-toolbar__content {
  padding: 0px !important;
} */

.top-bar {
  position: absolute;
  top: -25px;
  left: 0;
  width: 100%;
  background-color: #F44335;
}

.roundit{
  border-radius:40px 0px 0px 0px;
}

#core-app-bar a {
  text-decoration: none;
}
</style>
