<template>
  <v-col>
    <logged-header v-if="loginStatus"></logged-header>
    <open-header v-else></open-header>
  </v-col>
</template>
<script>

import LoggedHeader from "./AHeader/LoggedHeader";
import OpenHeader from "./AHeader/OpenHeader";
export default {
  name: "AHeader",
   data(){
    return {
      loginStatus: false,
    }
   },
  created(){
    this.firebase.auth().onAuthStateChanged(user=>{
      if(user){
        this.loginStatus = true;
        this.$store.commit("setLoginState",true);
      }else{
        this.loginStatus = false;
        this.$store.commit("setLoginState",false);
      }
    })
  },
  getToken(){
    this.$store.dispatch('getToken')
            .then((response) =>{
              localStorage.setItem('token', response)
              alert(response);
            })
            .catch(err => {
              self.loader = false;
              console.log(err)
            })
  },
  components:{
    LoggedHeader,
    OpenHeader,
  },
};
</script>

<style>
#core-app-bar {
  width: auto;
}

.roundit{
  border-radius:40px 0px 0px 0px;
}

#core-app-bar a {
  text-decoration: none;
}
</style>
