import {apolloClient} from  "../../main";
import * as marketRequestQuery from '../../gql/marketRequest.gql';
const marketRequests = {
  state: {
  },

  //Getters
  getters: {

  },

  //Mutations
  mutations: {

  },

  //ACTIONS
  actions: {
    submitRequest({commit,state},payload){
      return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: marketRequestQuery.create,
        variables: {
          data:payload
        }
      }).then(res=>{
        resolve(res);
      }).catch(err=>{
         reject(err);
      }) // ends
      }) // Promise

    },

  }
};

export default marketRequests;
