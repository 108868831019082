const auth = {
  namespaced: true,
  state: {
    user_token: null,
    user_data: null
  },

  //Getters
  getters: {
    getUserData: state => state.user_data,
    authState: state => state.user_token,
    getUserCompany: state => state.user_data.company ? state.user_data.company.id : null,
  },

  //Mutations
  mutations: {
    login(state, payload) {
      state.user_data = payload;
    },
    setUserToken(state, token){

      state.user_token = token
      token ?
        localStorage.setItem('token', token) :
        localStorage.removeItem('token')
    },
    setUserData(state, user){
      state.user_data = user
    }
  },

  //ACTIONS
  actions: {
    getToken() {
      return this.firebase.auth().currentUser.getIdToken(true)
    },

    setAuthentication({commit}, auth){
      localStorage.setItem('token', auth.token)
      commit('setUserData', auth.user)
      return auth.user
    },
    logoutUser({commit}){
      this.firebase.auth().signOut()
        .then( _ => {
            commit('setLoginState', false)
            localStorage.removeItem('token')
        })
        .catch(error => {
            commit('setFirebaseError', error.message);
            throw error
        });
    }
  }
};

export default auth;
