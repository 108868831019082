import {gql} from 'apollo-boost'

const fragment = `{
    id
    firebase_id
    first_name
    last_name
    email
    phone
    account_type
    addresses{
        location
    }
    company{
        id
    }
    account_type
    enable_notification
}
`

export const one = gql`
    query user($where: UserWhereUniqueInput!){
        user(where: $where)${fragment}
    }
`

export const create = gql`
    mutation createUser($data: UserCreateInput!){
        createUser(data: $data)${fragment}
    }
`

export const get = gql`
    query{
        users${fragment}
    }
`
export const update = gql`
    mutation updateUser(
        $data: UserUpdateInput!, 
        $where: UserWhereUniqueInput!
    ){
        updateUser(data: $data, where: $where)${fragment}
    }
`

export const remove = gql`
    mutation deleteUser($where: UserWhereUniqueInput!){
        deleteUser(where: $where)${fragment}
    }
`